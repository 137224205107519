import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import element2 from '../../../../assets/images/element2.png';
import m from '../../../../assets/images/M.png';
import a from '../../../../assets/images/A.png';
import r from '../../../../assets/images/R.png';
import { summaryPdfStyles } from '../../../../helpers/pdfCommonStyles';

import enTranslations from './translations/en.json';
import svTranslations from './translations/sv.json';

const styles = StyleSheet.create({
  ...summaryPdfStyles,
  title: {
    fontFamily: 'Como-Bold',
    fontSize: 32,
    marginTop: 30,
    letterSpacing: 2,
    width: 400,
    lineHeight: 1,
    marginBottom: 15,
  },
  info: {
    fontSize: 16,
    fontFamily: 'Como-SemiBold',
  },
  image: {
    height: 200,
    backgroundColor: 'lightblue',
    marginBottom: 100,
  },
  blockTitle: {
    fontFamily: 'Como-Bold',
    fontSize: 12,
    width: 180,
    marginBottom: 2,
  },
  items: {
    display: 'flex',
    flexDirection: 'row',
  },
  block: {
    width: '50%',
    fontSize: 12,
    textAlign: 'right',
    marginTop: -3,
  },
  blockText: {
    width: 240,
    fontSize: 10,
    lineHeight: 1.1,
    paddingRight: 60,
  },
  element2: {
    marginLeft: 170,
    height: 900,
    width: 80,
    position: 'absolute',
    left: 25,
    top: -110,
  },
  letter: {
    width: 30,
    height: 40,
    position: 'absolute',
    right: -115,
    top: -15,
  },
});

const FourPage = ({ data, lang }) => {
  const t = lang === 'en' ? enTranslations : svTranslations;
  const translations = t.fourPage;

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
          <View style={styles.agreementNumberBlock}>
            <Text style={styles.agreementNumber}>{data?.agreement_id}</Text>
            <Text style={styles.agreementNumber}>{data?.created_at}</Text>
          </View>
        </View>
        <View style={styles.content}>
          <View style={styles.items}>
            <View style={styles.leftSide}>
              <View style={{ ...styles.block, height: 300 }}>
                <Text style={styles.blockTitle}>{translations.step_M_frontdesk_title}</Text>
                <Text style={styles.blockText}>{translations.step_M_frontdesk_description}</Text>
                <Image src={m} style={styles.letter} />
              </View>

              <View style={{ ...styles.block, height: 140 }}>
                <Text style={styles.blockTitle}>{translations.step_A_frontdesk_title}</Text>
                <Text style={styles.blockText}>{translations.step_A_frontdesk_description}</Text>
                <Image src={a} style={styles.letter} />
              </View>

              <View style={{ ...styles.block, height: 182 }}>
                <Text style={styles.blockTitle}>{translations.step_R_frontdesk_title}</Text>
                <Text style={styles.blockText}>{translations.step_R_frontdesk_description}</Text>
                <Image src={r} style={styles.letter} />
              </View>

              <View style={styles.block}>
                <Text
                  style={{
                    ...styles.blockText,
                    fontFamily: 'Como-Bold',
                    fontSize: 14,
                    color: '#1F75FE',
                  }}
                >
                  {translations.day_45}
                </Text>
              </View>
            </View>

            <Image src={element2} style={styles.element2} />

            <View style={styles.rightSide}>
              <View
                style={{
                  ...styles.block,
                  textAlign: 'left',
                  marginLeft: 80,
                  height: 300,
                }}
              >
                <Text style={styles.blockTitle}>{translations.step_M_customer_title}</Text>
                <Text style={{ ...styles.blockText, marginLeft: 0 }}>{translations.step_M_customer_description}</Text>
              </View>

              <View
                style={{
                  ...styles.block,
                  textAlign: 'left',
                  marginLeft: 80,
                  height: 140,
                }}
              >
                <Text style={styles.blockTitle}>{translations.step_A_customer_title}</Text>
                <Text style={{ ...styles.blockText, marginLeft: 0 }}>{translations.step_A_customer_description}</Text>
              </View>

              <View style={{ ...styles.block, textAlign: 'left', marginLeft: 80 }}>
                <Text style={styles.blockTitle}>{translations.step_R_customer_title}</Text>
                <Text style={{ ...styles.blockText, marginLeft: 0 }}>{translations.step_R_customer_description}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

FourPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
  lang: PropTypes.string,
};

FourPage.defaultProps = {
  data: null,
  lang: 'sv',
};

export default FourPage;
