import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { summaryPdfStyles } from '../../../../helpers/pdfCommonStyles';

import enTranslations from './translations/en.json';
import svTranslations from './translations/sv.json';

const styles = StyleSheet.create({
  ...summaryPdfStyles,
  logo: {
    ...summaryPdfStyles.logo,
    position: 'absolute',
    right: 0,
  },
  footerName: {
    ...summaryPdfStyles.footerName,
    marginBottom: 3,
  },
  content: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: 670,
  },
  title: {
    fontFamily: 'Como-Bold',
    fontSize: 31,
    marginTop: 40,
    marginBottom: 20,
    letterSpacing: 2,
  },
  blockTitle: {
    fontFamily: 'Como-Bold',
    fontSize: 12,
  },
  textBlock: {
    fontSize: 9,
    // margin: '5px 0',
    marginTop: 5,
    flexShrink: 1,
    flexWrap: 'wrap',
    marginRight: 20,
    lineHeight: 1.1,
  },
  section: {
    margin: '10px 0',
  },
});

const TenPage = ({ data, lang }) => {
  const t = lang === 'en' ? enTranslations : svTranslations;
  const translations = t.tenPage;

  return (
    <Page style={styles.page} wrap>
      <View style={styles.container} wrap={false}>
        <View style={styles.logo}>
          <Image src={logo} />
          <View style={styles.agreementNumberBlock}>
            <Text style={styles.agreementNumber}>{data?.agreement_id}</Text>
            <Text style={styles.agreementNumber}>{data?.created_at}</Text>
          </View>
        </View>

        <Text style={styles.title}>{translations.title}</Text>
        <View style={styles.content}>
          <View style={styles.block}>
            <Text style={styles.blockTitle}>{translations.block_title_1}</Text>
            <Text style={styles.textBlock}>
              <Text style={{ fontFamily: 'Como-Bold' }}>{translations.block_sub_title_1_1} </Text>
              {translations.block_text_1_1}
            </Text>

            <Text style={styles.textBlock}>
              <Text style={{ fontFamily: 'Como-Bold' }}>{translations.block_sub_title_1_2} </Text>
              {translations.block_text_1_2}
            </Text>

            <Text style={styles.textBlock}>
              <Text style={{ fontFamily: 'Como-Bold' }}>{translations.block_sub_title_1_3} </Text>
              {translations.block_text_1_3}
            </Text>

            <Text style={styles.textBlock}>
              <Text style={{ fontFamily: 'Como-Bold' }}>{translations.block_sub_title_1_4} </Text>
              {translations.block_text_1_4}
            </Text>

            <Text style={styles.textBlock}>
              <Text style={{ fontFamily: 'Como-Bold' }}>{translations.block_sub_title_1_5} </Text>
              {translations.block_text_1_5}
            </Text>

            <Text style={styles.textBlock}>
              <Text style={{ fontFamily: 'Como-Bold' }}>{translations.block_sub_title_1_6} </Text>
              {translations.block_text_1_6}
            </Text>

            <Text style={styles.textBlock}>
              <Text style={{ fontFamily: 'Como-Bold' }}>{translations.block_sub_title_1_7} </Text>
              {translations.block_text_1_7}
            </Text>
          </View>

          <View style={styles.block}>
            <Text style={{ ...styles.blockTitle, marginTop: 10 }}>{translations.block_title_2}</Text>
            <Text style={styles.textBlock}>{translations.block_text_2_1}</Text>

            <Text style={styles.textBlock}>{translations.block_text_2_2}</Text>
          </View>

          <View style={styles.block}>
            <Text style={{ ...styles.blockTitle, marginTop: 10 }}>{translations.block_title_3}</Text>
            <Text style={styles.textBlock}>{translations.block_text_3_1}</Text>

            <Text style={styles.textBlock}>{translations.block_text_3_2}</Text>

            <Text style={styles.textBlock}>{translations.block_text_3_3}</Text>
          </View>

          <Text style={{ ...styles.blockTitle, marginTop: 10 }}>{translations.block_title_4}</Text>
          <Text style={styles.textBlock}>{translations.block_text_4_1}</Text>
          <Text style={styles.textBlock}>{translations.block_text_4_2}</Text>

          <Text style={styles.textBlock}>{translations.block_text_4_3}</Text>

          <Text style={styles.textBlock}>{translations.block_text_4_4}</Text>
          <View style={styles.block}>
            <Text style={styles.textBlock}>{translations.block_text_4_5}</Text>
            <Text style={styles.textBlock}>{translations.block_text_4_6}</Text>
          </View>

          <View style={styles.block}>
            <Text style={styles.textBlock}>{translations.block_text_4_7}</Text>
          </View>

          <Text style={{ ...styles.blockTitle, marginTop: 10 }}>{translations.block_title_5}</Text>
          <Text style={styles.textBlock}>{translations.block_text_5_1}</Text>

          <Text style={styles.textBlock}>{translations.block_text_5_2}</Text>

          <Text style={styles.textBlock}>{translations.block_text_5_3}</Text>

          <Text style={styles.textBlock}>{translations.block_text_5_4}</Text>

          <Text style={styles.textBlock}>{translations.block_text_5_5}</Text>

          {lang === 'sv' ? <Text style={styles.textBlock}>{translations.block_text_5_6}</Text> : null}
        </View>
      </View>

      <View style={styles.footer}>
        <View style={styles.footerName}>
          <Text style={{ fontFamily: 'Como-Bold' }}>{t.common.offer} </Text>
          <Text style={{ fontFamily: 'Como-SemiBold' }}>{data?.company.name}</Text>
        </View>
        <Text style={{ fontFamily: 'Como-Medium' }}>{data?.created_at}</Text>
      </View>
    </Page>
  );
};

TenPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
  lang: PropTypes.string,
};

TenPage.defaultProps = {
  data: null,
  lang: 'sv',
};

export default TenPage;
