import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../../components/simple/Select';
import styles from './styles.module.scss';

const ServicesTable = ({ tableStructure, tableValues, handleChange, handlePingChange, tableName, name }) => (
  <table className={styles.container}>
    <thead className={styles.header}>
      <tr className={styles.header}>
        <th>{name}</th>
        <th>Antal</th>
        <th>Utpris/mån</th>
        <th>TB</th>
        <th>Totalt</th>
      </tr>
    </thead>
    {tableStructure &&
      Object.keys(tableStructure).map((key) => (
        <tbody className={styles.tableBody} key={key}>
          <tr>
            <th className={styles.sectionTitle}>{tableStructure[key].title}</th>
          </tr>
          {tableStructure[key].items.map((row) => (
            <tr className={styles.tableRow} key={row.id}>
              <th className={styles.rowTitle}>{row.name}</th>
              <th>
                <input
                  className={styles.input}
                  name={row.id}
                  type="number"
                  onChange={(e) => handleChange(e, tableName)}
                  value={(tableValues && tableValues[row.id]?.amount) || ''}
                  min="0"
                />
              </th>
              <th>
                {/* 
                  prices.length => drop down

                  !prices.length & !row?.price_can_change = > no edit

                  !prices.length & row?.price_can_change => input
                
                */}

                {!row.prices.length ? (
                  <input
                    className={styles.pingInput}
                    name={row.id}
                    type="number"
                    disabled={!row?.price_can_change}
                    value={tableValues && tableValues?.[row.id]?.per_month}
                    onChange={(e) => handlePingChange(e)}
                  />
                ) : (
                  <Select
                    onChange={(e) => handlePingChange(e, tableName)}
                    values={row?.prices ?? []}
                    name={row.id}
                    value={tableValues && tableValues?.[row.id]?.per_month}
                  />
                )}
              </th>
              <th>
                <span>{(tableValues && tableValues[row.id]?.total_tb) || 0} kr</span>
              </th>
              <th>
                <span>{(tableValues && tableValues[row.id]?.total) || 0} kr</span>
              </th>
            </tr>
          ))}
        </tbody>
      ))}
  </table>
);

ServicesTable.propTypes = {
  tableStructure: PropTypes.arrayOf(PropTypes.shape),
  tableValues: PropTypes.objectOf(PropTypes.shape),
  handleChange: PropTypes.func,
  handlePingChange: PropTypes.func,
  tableName: PropTypes.string,
  name: PropTypes.string,
};

ServicesTable.defaultProps = {
  tableStructure: null,
  tableValues: null,
  handleChange: () => {},
  handlePingChange: () => {},
  tableName: '',
  name: '',
};

export default ServicesTable;
