import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { hardwarePotPdfStyles } from '../../../../helpers/pdfCommonStyles';
import { getTotal } from '../../../../helpers/finalInvoiceAndHardwarePot';
import { feeGroup } from '../../../../helpers/tablesValues';

import logo from '../../../../assets/images/Logo.png';

const styles = StyleSheet.create({
  ...hardwarePotPdfStyles,
});

// eslint-disable-next-input arrow-body-style
const HardwarePot = ({ data }) => {
  const total = getTotal(data?.fees, feeGroup.HARDWARE_POT);

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={{ ...styles.subTitle, marginBottom: 20 }}>Bilaga,</Text>
          <Text style={{ ...styles.title, marginBottom: 20 }}>Pingaway Avtal</Text>

          <Text style={{ ...styles.textBold, marginBottom: 5 }}>Hårdvarupott</Text>
          <Text style={styles.text}>
            Kund erhåller en hårdvarupott om {total} kr exkl. moms. Kund har rätt att avropa beloppet under avtalstid.
          </Text>

          <View style={styles.form}>
            <View style={styles.block}>
              <View style={styles.item}>
                <View style={styles.input} />
                <Text style={styles.textBold}>Namnteckning firmatecknare</Text>
              </View>

              <View style={styles.item}>
                <View style={styles.input} />
                <Text style={styles.textBold}>Namnförtydligande</Text>
              </View>

              <View style={styles.item}>
                <View style={styles.input} />
                <Text style={styles.textBold}>Ort & Datum</Text>
              </View>
            </View>

            <View style={styles.block}>
              <View style={styles.item}>
                <View style={styles.input}>
                  <Text style={{ ...styles.text }}>{data?.company?.name}</Text>
                </View>
                <Text style={styles.textBold}>Företagsnamn</Text>
              </View>
              <View style={styles.item}>
                <View style={styles.input}>
                  <Text style={{ ...styles.text }}>{data?.company?.organization_number}</Text>
                </View>
                <Text style={styles.textBold}>Organisationsnummer</Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.footer}>
        <View style={styles.footerCol}>
          <Text style={styles.footerText}>Frontdesk Nordic AB</Text>
          <Text style={styles.footerText}>556907-3256</Text>
          <Text style={styles.footerText}>www.pingaway.se</Text>
        </View>
        <View style={styles.footerCol}>
          <Text style={styles.footerText}>Drakegatan 10</Text>
          <Text style={styles.footerText}>412 50 Göteborg</Text>
        </View>
        <View style={styles.footerCol}>
          <Text style={styles.footerText}>Hangar 5</Text>
          <Text style={styles.footerText}>168 67 Bromma</Text>
        </View>
        <View style={{ ...styles.footerCol, flex: 1.5 }}>
          <View style={styles.logo}>
            <Image src={logo} />
          </View>
        </View>
      </View>
    </Page>
  );
};

HardwarePot.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
};

HardwarePot.defaultProps = {
  data: null,
};

export default HardwarePot;
