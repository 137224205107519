import React from 'react';
import Router from './router/Router';
import './styles/global.css';
import './styles/_main.scss';

const App = () => (
  <div className="App">
    <Router />
  </div>
);

export default App;
