import React from 'react';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { roamingPdfStyles } from '../../../../helpers/pdfCommonStyles';

const styles = StyleSheet.create({
  ...roamingPdfStyles,
  title: {
    fontSize: 31,
    fontFamily: 'Como-ExtraBold',
    marginBottom: 20,
  },
  container: {
    ...roamingPdfStyles.container,
    width: '100%',
  },
  cell: {
    ...roamingPdfStyles.cell,
    width: 100,
  },
});

const tableCreator = (
  roamingzon,
  inomLandet,
  tillVerige,
  tillZon1,
  tillZon2,
  tillZon3,
  tillZon7,
  emotSamtal,
  emotSMS,
  skickaSMS,
) => ({
  roamingzon,
  inomLandet,
  tillVerige,
  tillZon1,
  tillZon2,
  tillZon3,
  tillZon7,
  emotSamtal,
  emotSMS,
  skickaSMS,
});

const tableValues = [
  tableCreator('1', 'EU*/0.425', 'EU*/0.425', 'EU*/0.425', '5,00', '16,00', '48,00', 'EU*/ 0,15', '0', 'EU*/ 0,13'),
  tableCreator('2a', '1,20', '1,20', '1,20', '5,00', '16,00', '48,00', '4,00', '0', '0,40'),
  tableCreator('2b', '2,40', '2,40', '2,40', '5,00', '16,00', '48,00', '4,00', '0', '2.40'),
  tableCreator('2c', '10,00', '10,00', '10,00', '10,00', '16,00', '48,00', '4,00', '0', '3,20'),
  tableCreator('3', '20,00', '20,00', '20,00', '20,00', '20,00', '48,00', '6,00', '0', '3,20'),
  tableCreator('4', '16,00', '16,00', '16,00', '16,00', '16,00', '48,00', '6,00', '0', '3,20'),
  tableCreator('5a', '20,00', '20,00', '20,00', '20,00', '20,00', '48,00', '8,00', '0', '3,20'),
  tableCreator('5b', '26,00', '26,00', '26,00', '26,00', '26,00', '48,00', '8,00', '0', '3,20'),
  tableCreator('5c', '40,00', '40,00', '40,00', '40,00', '40,00', '48,00', '18,00', '0', '3,20'),
  tableCreator('6', '26,00', '26,00', '26,00', '26,00', '26,00', '48,00', '28,00', '0', '3,20'),
  tableCreator('7', '48,00', '48,00', '48,00', '48,00', '48,00', '48,00', '48,00', '0', '4,0'),
  tableCreator('Thailand', '6,00', '6,00', '16,00', '16,00', '20,00', '48,00', '6,00', '0', '3,20'),
];

const FirstPage = () => (
  <Page style={styles.page}>
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>
        <View>
          <Text style={styles.title}>Pingaway Trafikprislista - Roaming</Text>
        </View>
      </View>

      <View style={styles.containerWrapper}>
        <View style={styles.tableContainer}>
          <Text style={styles.tableTitle}>SAMTALSTRAFIK/ SMS</Text>
          <View>
            <View style={styles.tableHeader}>
              <Text style={{ ...styles.cell, width: 80 }}>Roamingzon</Text>
              <View style={{ ...styles.cell, width: 80 }}>
                <Text>Inom landet</Text>
                <Text style={styles.cellSub}>(SEK/min)</Text>
              </View>
              <View style={{ ...styles.cell, width: 70 }}>
                <Text>Till Sverige</Text>
                <Text style={styles.cellSub}>(SEK/min)</Text>
              </View>
              <View style={{ ...styles.cell, width: 60 }}>
                <Text>Till Zon 1</Text>
                <Text style={styles.cellSub}>(SEK/min)</Text>
              </View>
              <View style={{ ...styles.cell, width: 85 }}>
                <Text>Till Zon 2a-c</Text>
                <Text style={styles.cellSub}>(SEK/min)</Text>
              </View>
              <View style={{ ...styles.cell, width: 80 }}>
                <Text>Till Zon 3-6</Text>
                <Text style={styles.cellSub}>(SEK/min)</Text>
              </View>
              <View style={{ ...styles.cell, width: 65 }}>
                <Text>Till Zon 7</Text>
                <Text style={styles.cellSub}>(SEK/min)</Text>
              </View>
              <View style={{ ...styles.cell, width: 100 }}>
                <Text>Ta emot samtal</Text>
                <Text style={styles.cellSub}>(SEK/min)</Text>
              </View>
              <Text style={{ ...styles.cell, width: 85 }}>Ta emot SMS</Text>
              <View style={{ ...styles.cell, width: 70 }}>
                <Text>Skicka SMS</Text>
                <Text style={styles.cellSub}>(SEK/sms)</Text>
              </View>
            </View>
            <>
              {tableValues.map((item) => (
                <View style={styles.row} key={item.roamingzon}>
                  <Text style={{ ...styles.cell, width: 80 }}>{item.roamingzon}</Text>
                  <Text style={{ ...styles.cell, width: 80 }}>{item.inomLandet}</Text>
                  <Text style={{ ...styles.cell, width: 70 }}>{item.tillVerige}</Text>
                  <Text style={{ ...styles.cell, width: 60 }}>{item.tillZon1}</Text>
                  <Text style={{ ...styles.cell, width: 85 }}>{item.tillZon2}</Text>
                  <Text style={{ ...styles.cell, width: 80 }}>{item.tillZon3}</Text>
                  <Text style={{ ...styles.cell, width: 65 }}>{item.tillZon7}</Text>
                  <Text style={{ ...styles.cell, width: 100 }}>{item.emotSamtal}</Text>
                  <Text style={{ ...styles.cell, width: 85 }}>{item.emotSMS}</Text>
                  <Text style={{ ...styles.cell, width: 70 }}>{item.skickaSMS}</Text>
                </View>
              ))}
            </>
          </View>
          <Text style={styles.tableInfo}>
            Debiteringsintervall: Från mobil i Zon 1 och 2a, 2b gäller ett debiteringsintervall på startintervall om 30
            sek med efterföljande intervall på 1 sekund.För mottagna samtal i Zon 1 och 2a, 2b gäller ett
            debiteringsintervall på 1 sekund. För samtal ringda och mottagna i övriga zoner gäller ett
            debiteringsintervall på 60 sekunder.*Vid Samtal, SMS, & surf inom EU så gäller samma priser som kund har
            inrikes.Har kund en flatratetjänst så ingår 3000 samtalsminuter/SMS/ MMS i licenskostnaden utöver fria
            internsamtal. Betalsamtal och betal-SMS ingår ej. Efter uppnådd pott debiteras kund enligt Rörlig prislista.
            (Frontdesk har rätt att ta ut maxpris 0,425 SEK (exkl moms) per minut om inte samtal nyttjas i normal
            användning enligt EUs föreskrifter om EU-roaming.)Öppningsavgift samtliga zoner 0,79 kr
          </Text>
        </View>
      </View>
    </View>
    <View style={styles.footer}>
      <Text>www.pingaway.se</Text>
    </View>
  </Page>
);

export default FirstPage;
