/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const TotalTBCard = ({ title, firstLabel, secondLabel, thirdLabel, amount, pingRam, pingSwitch, partner_type }) => (
  <div className={styles.container}>
    <div className={styles.title}>
      <p>{title}</p>
    </div>
    <div className={styles.body}>
      <div className={styles.section}>
        <p>
          {pingRam} {amount}
        </p>
        <span>{firstLabel}</span>
      </div>
      <div className={styles.section}>
        <p>
          {pingSwitch} {amount}
        </p>
        <span>{secondLabel}</span>
      </div>
      <div className={styles.section}>
        <p>
          {Math.round((pingRam + pingSwitch) * partner_type)} {amount}
        </p>
        <span>{thirdLabel}</span>
      </div>
    </div>
  </div>
);

TotalTBCard.propTypes = {
  title: PropTypes.string,
  firstLabel: PropTypes.string,
  secondLabel: PropTypes.string,
  thirdLabel: PropTypes.string,
  amount: PropTypes.string,
  pingRam: PropTypes.number,
  partner_type: PropTypes.number,
  pingSwitch: PropTypes.number,
};

TotalTBCard.defaultProps = {
  title: '',
  firstLabel: 'Ping RAM',
  secondLabel: 'Ping Switch',
  thirdLabel: 'Summa',
  amount: 'kr',
  pingRam: 0,
  partner_type: 1,
  pingSwitch: 0,
};

export default TotalTBCard;
