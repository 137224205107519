import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { slaPdfStyles } from '../../../../helpers/pdfCommonStyles';

import logo from '../../../../assets/images/Logo.png';

const styles = StyleSheet.create({
  ...slaPdfStyles,

  formula: {
    position: 'relative',
    padding: '0 40px',
    paddingBottom: 20,
  },
});

// eslint-disable-next-line arrow-body-style
const ThirdPage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>

        <View style={styles.content}>
          <Text style={styles.subTitle}>TILLGÄNGLIGHET</Text>
          <Text style={styles.text}>
            Tillgängligheten mäts per kvartal enligt formeln nedan, inom ramen för Frontdesk ansvarsområde.
          </Text>

          <View style={styles.formula}>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ ...styles.text, fontFamily: 'Como-Bold' }}>Tillgänglighet</Text>
              <Text style={{ ...styles.text }}> =</Text>
              <Text style={{ ...styles.text, borderBottom: 0.3, borderBottomColor: '#10225c' }}>
                (Total tid - Otillgänglig Tid under servicetid)
              </Text>
            </View>
            <Text style={{ ...styles.textBlock, position: 'absolute', right: 200, bottom: 8 }}>Total tid</Text>
          </View>

          {/* ADD formul */}

          <Text style={styles.subTitle}>OTILLGÄNGLIG TID</Text>
          <Text style={styles.text}>
            För att Otillgänglig Tid skall kunna beräknas måste en felanmälan göras till Frontdesk. Med Otillgänglig Tid
            avses tidsperioden från då kund meddelat Frontdesk att ett Omfattande Fel föreligger, som bekräftas av
            Frontdesk, till dess Frontdesk åtgärdat felet och meddelat kund att tjänsten åter är tillgänglig. Med
            Otillgänglig Tid avses endast tid inom servicetid. RFO begärs
          </Text>

          <Text style={{ ...styles.subTitle, marginBottom: 5 }}>
            Följande skäl är inte att betrakta som Otillgänglig Tid:
          </Text>
          <Text style={styles.listItem}>• Otillgänglig Tid på grund av planerat arbete inom Servicefönster.</Text>
          <Text style={styles.listItem}>
            • Otillgänglig Tid orsakad av Kunden eller tredjepartsleverantör som inte är kontrakterad av Telavox.
          </Text>
          <Text style={styles.listItem}>
            • Otillgänglig Tid orsakad av Kunden eller tredjepartsleverantör som inte är kontrakterad av Telavox.
          </Text>
          <Text style={styles.listItem}>
            • Otillgänglig Tid på grund av att Kunden underlåter att bistå Frontdesk vi felavhjälpning.
          </Text>
          <Text style={styles.listItem}>
            • Otillgänglig Tid vid Force Majeure-händelse (se vidare Allmänna Villkor).
          </Text>

          <Text style={styles.text}>
            Om tjänsten inte är tillgänglig på grund av vad som anges ovan, utgör detta således inte Otillgänglig Tid
            och därmed föreligger inte grund för kompensation. Om ett planerat driftavbrott tar längre tid än angivet
            och därmed faller utanför aviserat servicefönster, är kunden dock berättigad till kompensation.
          </Text>

          <Text style={styles.subTitle}>PLANERADE DRIFTSAVBROTT</Text>
          <Text style={styles.text}>
            Frontdesk arbetar inte med statiska servicefönster utan aviserar ett driftsavbrott minst 10 (tio)
            arbetsdagar i förväg, förlägger det till nattetid (00:00- 05:00) för minimal påverkan på kundupplevelsen och
            endast vid det fall detta är absolut nödvändigt. Planerade driftsavbrott får inte överstiga 1h/kvartal.
            Planerade driftavbrott som Frontdesk ej informerat om i tid samt oplanerade driftavbrott räknas som
            Otillgänglig tid.
          </Text>

          <Text style={styles.subTitle}>KOMPENSATION</Text>
          <Text style={styles.text}>
            Frontdesk garanterar tillgänglighet i enlighet med ovanstående servicenivåer. Om tillgängligheten
            underskrider överenskommen servicenivå, beräknad per kvartal och under servicetid, skall kompensation utgå
            enligt vad som anges i nedanstående tabell.
          </Text>
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={styles.footerName}>www.frontdesk.se</Text>
      </View>
    </Page>
  );
};

ThirdPage.propTypes = {};

ThirdPage.defaultProps = {};

export default ThirdPage;
