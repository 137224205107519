import * as Yup from 'yup';
import dayjs from 'dayjs';

const VIEWER = 'viewer';
const SIGNING = 'signing_party';

export const firstStepInitialValues = {
  name: '',
  organization_number: '',
  postal_address: '',
  zip_code: '',
  state: '',
  // country: '',
  phone_number: '+46',
  date: dayjs().format('DD-MM-YYYY'),
  expire: '',
};

export const firstStepValidationSchema = Yup.object().shape({
  name: Yup.string().required('Detta fält är obligatoriskt'),
  organization_number: Yup.string().required('Detta fält är obligatoriskt'),
  postal_address: Yup.string().required('Detta fält är obligatoriskt'),
  zip_code: Yup.string().max(5, 'Max längd är 5 symboler').required('Detta fält är obligatoriskt'),
  state: Yup.string().required('Detta fält är obligatoriskt'),
  // country: Yup.string().required('Detta fält är obligatoriskt'),
  phone_number: Yup.string().required('Detta fält är obligatoriskt'),
  expire: Yup.string().required('Detta fält är obligatoriskt'),
});

export const secondStepValidationSchema = {
  signers: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Detta fält är obligatoriskt'),
      email: Yup.string().email('Ange en korrekt e-postadress').required('Detta fält är obligatoriskt'),
      phone_number: Yup.string().required('Detta fält är obligatoriskt'),
      social_security_number: Yup.string().when('type', {
        is: VIEWER,
        then: Yup.string().notRequired(),
        otherwise: Yup.string().required('Detta fält är obligatoriskt'),
      }),
    }),
  ),
  // real_principals: Yup.array().of(
  //   Yup.object().shape({
  //     name: Yup.string().required('Detta fält är obligatoriskt'),
  //     email: Yup.string().email('Ange en korrekt e-postadress').required('Detta fält är obligatoriskt'),
  //     phone_number: Yup.string().required('Detta fält är obligatoriskt'),
  //     social_security_number: Yup.string().required('Detta fält är obligatoriskt'),
  //     percentage: Yup.number().notRequired(),
  //   }),
  // ),
  telephone_manager: Yup.object().shape({
    name: Yup.string().required('Detta fält är obligatoriskt'),
    email: Yup.string().email('Ange en korrekt e-postadress').required('Detta fält är obligatoriskt'),
    phone_number: Yup.string().required('Detta fält är obligatoriskt'),
  }),
  manager: Yup.object().shape({
    username: Yup.string().notRequired(),
    email: Yup.string().notRequired(),
    phone_number: Yup.string().notRequired(),
    partner_name: Yup.string().nullable().required('Detta fält är obligatoriskt'),
    partner_org_num: Yup.string().nullable().required('Detta fält är obligatoriskt'),
  }),
};
