import React from 'react';
import PropTypes from 'prop-types';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import ThirdPage from './ThirdPage';

const Grenke = ({ data }) => (
  <>
    <FirstPage data={data} />
    <SecondPage />
    <ThirdPage data={data} />
  </>
);

Grenke.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
};

Grenke.defaultProps = {
  data: null,
};

export default Grenke;
