import {
  downloadAgreements,
  newAgreementStep1,
  newAgreementStep2,
  newAgreementStep3,
  startedAgreements,
} from '../router/routes';

const valuesCreator = (id, text, color, path) => ({
  id,
  text,
  color,
  path,
});

// eslint-disable-next-line import/prefer-default-export
export const mainPageLinks = [
  valuesCreator(1, 'Mina avtal', 'yellow', downloadAgreements),
  valuesCreator(2, 'Pågående avtal', 'purple', startedAgreements),
  valuesCreator(3, 'Skapa nytt avtal', 'blue', newAgreementStep1),
];

export const choiseStep = (step) => {
  const values = {
    1: newAgreementStep1,
    2: newAgreementStep2,
    3: newAgreementStep3,
  };

  return values[step];
};
