import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { slaPdfStyles } from '../../../../helpers/pdfCommonStyles';

import logo from '../../../../assets/images/Logo.png';
import img from '../../../../assets/images/sla_img_1.png';

const styles = StyleSheet.create({
  ...slaPdfStyles,
});

// eslint-disable-next-line arrow-body-style
const FirstPage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>

        <View style={styles.content}>
          <Text style={{ ...styles.title, marginBottom: 10, color: '#3b63a0' }}>
            Frontdesk SLA -{'\n'}Service Level Agreement
          </Text>

          <Text style={styles.subTitle}>FRONTDESK GARANTERAR OPERATIV DRIFT</Text>
          <Text style={styles.text}>
            Frontdesk molntjänster produceras i två fullt redundanta miljöer i Lund och Stockholm där såväl
            batteribackup (UPS) som dieselaggregat garanterar drift även vid långvarigt strömbortfall under dygnets alla
            timmar, årets alla dagar. För kommunikation ut på telenätet är Frontdesk en fullvärdig IP (Internet
            provider) och har multipla och redundanta accesser i egenägd fiber från båda lokationerna, samt BGP-peering
            avtal med de ledande leverantörerna på våra marknader. Om någon access utnyttjas med 40% eller mer av sin
            kapacitet sker en utökning för att undvika överbelastningsproblematik.
          </Text>

          <Text style={styles.text}>
            Frontdesk arbetar med agil tjänsteutveckling vilket innebär att vi löpande lanserar ny funktionalitet för
            att ständigt vara en ledande leverantör inom vårt område. Större förändringar i plattformen godkänns av
            minst 3 utvecklare, efter att de testats av hos respektive avdelning. Vi uppgraderar och förbättrar även
            befintliga tjänster - ibland flera gånger per dag, utan att det påverkar daglig drift och användaren. Ett
            agilt arbetssätt inom tjänsteutveckling möjliggör förutom konti-nuerliga förbättringar dessutom en snabb
            återgång om vi ser störning i tjänsten. Att jämföra med leverantörer som väntar in och samlar rättningar och
            uppgraderingar till stora versionslanseringar med fasta intervaller.
          </Text>

          <Text style={styles.text}>
            Frontdesk samtliga system övervakas 24/7/365 i Malmö och Stockholm av Network Operation Center, NOC.
            Frontdesk system utsätts ständigt för automatiserade funktionstester med hjälp av bot teknik. Vid
            abnormiteter eller systemlarm är vår driftsorganisation standby för att påbörja åtgärder inom några få
            minuter, vilket medger att vi ofta hanterat uppkommet ärende innan våra användare påverkats.
          </Text>
          <Image src={img} />
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={styles.footerName}>www.frontdesk.se</Text>
      </View>
    </Page>
  );
};

FirstPage.propTypes = {};

FirstPage.defaultProps = {};

export default FirstPage;
