/* eslint-disable react/no-array-index-key */
import React from 'react';
import dayjs from 'dayjs';
import styles from '../pages/NewAgreementSecondPage/NewAgreementSecondStep/styles.module.scss';
import { Input } from '../components/simple';

const day = dayjs().format('YYYY-MM-DD');

const stepCreator = (label, name, type, readonly, min, placeholder) => ({
  label,
  name,
  type,
  readonly,
  min,
  placeholder,
});

export const firstStepValues = [
  stepCreator('Företagsnamn:', 'name', 'text'),
  stepCreator('Organisationsnummer:', 'organization_number', 'text'),
  stepCreator('Datum:', 'date', 'text', true),
  stepCreator('Postadress:', 'postal_address', 'text'),
  stepCreator('Postnummer:', 'zip_code', 'text'),
  stepCreator('Ort:', 'state', 'text'),
  // stepCreator('Land:', 'country', 'text'),
  stepCreator('Telefonnummer:', 'phone_number', 'text'),
  stepCreator('Offertens giltighetstid:', 'expire', 'date', null, day),
];

export const secondStepValues = {
  signers: {
    label: 'Firmatecknare:',
    items: [
      stepCreator('Förnamn & efternamn:', 'name', 'text'),
      stepCreator('E-post:', 'email', 'text'),
      stepCreator('Mobilnummer:', 'phone_number', 'text'),
      stepCreator('Personnummer:', 'social_security_number', 'text'),
    ],
  },
  telephone_manager: {
    label: 'Telefonansvarig/Teknisk Kontaktperson:',
    items: [
      stepCreator('Förnamn & efternamn:', 'name', 'text'),
      stepCreator('E-post:', 'email', 'text'),
      stepCreator('Mobilnummer:', 'phone_number', 'text'),
    ],
  },
  real_principals: {
    label: 'Verklig Huvudman:',
    items: [
      stepCreator('Förnamn & efternamn:', 'name', 'text'),
      stepCreator('E-post:', 'email', 'text'),
      stepCreator('Mobilnummer:', 'phone_number', 'text'),
      stepCreator('Personnummer:', 'social_security_number', 'text'),
      stepCreator('Verklig huvudman 1:', 'percentage', 'text', null, null, 'Ägarandel i %'),
    ],
  },
  manager: {
    label: 'Säljare:',
    items: [
      stepCreator('Förnamn & efternamn:', 'username', 'text'),
      stepCreator('E-post:', 'email', 'text'),
      stepCreator('Mobilnummer:', 'phone_number', 'text'),

      stepCreator('Företagsnamn:', 'partner_name', 'text'),
      stepCreator('Organisationsnummer:', 'partner_org_num', 'text'),
    ],
  },
};

export const checkboxValues = {
  copyInfo: [
    stepCreator('Kopiera till Verklig Huvudman', 'copyManager'),
    stepCreator('Kopiera till Telefaniansvarig', 'copyPrincipal'),
  ],
  addPerson: [stepCreator('Addera ytterligare Verklig Huvudman', 'addPrincipal')],
};

export const selectValues = [12, 24, 36, 48, 60];

export const formCreator = (value, formik) =>
  secondStepValues[value].items.map((input) => (
    <div className={styles.inputWrapper} key={input.name}>
      <Input
        name={`${value}.${input.name}`}
        label={input.label}
        type={input.type}
        onChange={formik.handleChange}
        value={formik.values[value][input.name] || ''}
        className={`${styles.input} ${formik.touched[value] && formik.errors[value] && styles.errorInput}`}
        labelStyle={styles.label}
        placeholder={input.placeholder}
      />
      {formik.touched[value] && formik.errors[value] && (
        <div className={styles.error}>{formik.errors[value][input.name]}</div>
      )}
    </div>
  ));

export const formListCreator = (value, formik, title, renderCheckBox, renderTitle = null, filter = (v) => v) => {
  // eslint-disable-next-line prefer-destructuring
  const items = secondStepValues[value].items;

  return formik.values[value].map((item, index) => (
    <div className={styles.copyPrincipal} key={index}>
      <h2 className={styles.title}>{renderTitle?.(item, index) || title} </h2>
      <div className={styles.realPrincipalBlock}>
        <div className={styles.principalInputs}>
          {items
            .filter((t) => filter(t, item))
            .map((input) => (
              <div className={styles.inputWrapper} key={input.name}>
                <Input
                  name={`${value}.${index}.${input.name}`}
                  label={input.label}
                  type={input.type}
                  onChange={formik.handleChange}
                  value={formik.values[value][index][input.name] || ''}
                  className={`${styles.input} ${formik.touched[value] && formik.errors[value] && styles.errorInput}`}
                  labelStyle={styles.label}
                  placeholder={input.placeholder}
                />
                {formik.touched[value] && formik.errors[value] && (
                  <div className={styles.error}>{formik.errors?.[value]?.[index]?.[input?.name]}</div>
                )}
              </div>
            ))}
        </div>
        {renderCheckBox?.(index, item)}
      </div>
    </div>
  ));
};
