import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { personalDataServiceAgreementPdfStyles } from '../../../../helpers/pdfCommonStyles';

const styles = StyleSheet.create({
  ...personalDataServiceAgreementPdfStyles,
});

// eslint-disable-next-line arrow-body-style
const SecondPage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold} />
            </View>

            <View style={styles.col_2}>
              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>”Frontdesk”</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>
                    avser den part som anges i ingressen ovan och som agerar Personuppgiftsbiträde enligt detta
                    Biträdesavtal.
                  </Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>”Ikraftträdandet”</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>
                    avser den dag Dataskyddsförordningen träder i kraft, d.v.s. den 25 maj 2018.
                  </Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>”Kunden”</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>
                    avser den part som anges i ingressen ovan och som agerar Personuppgiftsansvarig enligt detta
                    Biträdesavtal. I den mån Kunden ingår detta Biträdesavtal för andra tjänstemottagares räkning i
                    enlighet med Avtalet ska dock definitionen ”Kund” i tillämpliga delar även avse sådana
                    tjänstemottagare om inte annat uttryckligen framgår av detta Biträdesavtal eller Avtalet.
                  </Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>”Leverantören”</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>
                    avser den part som anges i ingressen ovan och som agerar Personuppgiftsbiträde enligt detta
                    Biträdesavtal.
                  </Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>”Personuppgifter”</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>
                    avser de personuppgifter, som Leverantören Behandlar för Kundens räkning under detta Biträdesavtal
                    med hänsyn till hur begreppet personuppgifter bestäms enligt vid var tid gällande Dataskyddslagar.
                  </Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>”Personuppgiftsansvarig”</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>
                    avser personuppgiftsansvarig såsom begrep pet bestäms enligt vid var tid gällande Data skyddslagar.
                  </Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>”Personuppgiftsbiträde”</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>
                    avser personuppgiftsbiträde såsom begreppet bestäms enligt vid var tid gällande Dataskyddslagar.{' '}
                  </Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>”Registrerad”</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>avser den fysiska person som en Personuppgift avser. </Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>”Tillsynsmyndighet”</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>
                    avser den eller de tillsynsmyndigheter som har behörighet att bedriva tillsyn över hantering av
                    personuppgifter eller anses vara berörd tillsynsmyndighet enligt gällande Dataskyddslagar.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

SecondPage.propTypes = {};

SecondPage.defaultProps = {};

export default SecondPage;
