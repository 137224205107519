import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, Image, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/grenke-logo.png';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'ArialRegular',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#000',
  },
  container: {
    width: '90%',
    margin: '0 auto',
  },
  logo: {
    height: 70,
    width: 120,
    marginLeft: 'auto',
    marginTop: 25,
    color: 'grey',
    fontSize: 16,
    textAlign: 'center',
  },
  title: {
    fontSize: 20,
    fontFamily: 'ArialBold',
    height: 80,
  },
  text: {
    fontSize: 10,
    width: 400,
  },
  inputs: {
    display: 'flex',
    flexDirection: 'row',
  },
  name: {
    borderBottom: 1,
    width: 250,
    padding: '5px 0',
    marginRight: 20,
    fontFamily: 'ArialBold',
    fontSize: 16,
  },
  number: {
    borderBottom: 1,
    width: 150,
    padding: '5px 0',
    marginRight: 20,
    fontFamily: 'ArialBold',
    fontSize: 16,
  },
  tableText: {
    fontFamily: 'Helvetica',
    fontSize: 16,
    padding: '3px 3px',
  },
  caption: {
    fontSize: 10,
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 5,
  },
  cell: {
    border: 1,
    width: 170,
    height: 25,
  },
  footer: {
    paddingBottom: 20,
    width: '90%',
    margin: '0 auto',
  },
});

const ThirdPage = ({ data }) => (
  <Page style={styles.page}>
    <View style={styles.container}>
      <View style={styles.logo}>
        <Image src={logo} />
      </View>

      <Text style={styles.title}>Information om Verklig huvudman</Text>

      <Text style={{ fontSize: 12, fontFamily: 'ArialBold' }}>Kundkännedom</Text>
      <Text style={{ ...styles.text, marginBottom: 20 }}>
        GRENKELEASING AB måste enligt lag (2009:62) om åtgärder mot penningtvätt och finansiering av terrorism
        säkerställa att vi väl känner våra kunder och deras transaktioner. Ett föreskrivet led i åtgärderna att uppnå
        kundkännedom är att vi måste inhämta information om våra kunders identitet och de så kallade verkliga
        huvudmännen (dvs, en fysisk person för vars räkning någon handlar eller, om kunden är en juridisk person, en
        fysisk person som kan bestämma över kunden. Mot denna bagrund ber vi er lämna nedanstående uppgifter om det
        bolag som är vår kund. Om bolaget ägs av ett annat bolag ber vi er också lämna motsvarande information om detta
        bolag.
      </Text>

      <Text style={{ fontSize: 12, fontFamily: 'ArialBold' }}>Kund</Text>
      <View style={styles.inputs}>
        <View>
          <Text style={styles.name}>{data?.company.name}</Text>
          <Text style={styles.caption}>Företagsnamn</Text>
        </View>
        <View>
          <Text style={styles.number}>{data?.company.organization_number}</Text>
          <Text style={styles.caption}>Organisationsnummer</Text>
        </View>
      </View>

      <Text
        style={{
          fontSize: 12,
          fontFamily: 'ArialBold',
          marginTop: 30,
          marginBottom: 5,
        }}
      >
        Ägare
      </Text>
      <Text style={{ ...styles.text, width: 300, marginBottom: 5 }}>
        Följande fysiska personer äger aktier motsvarande 25% eller mer av kapitalet eller röster i företaget. Om
        innehavet är indirekt ska även anges genom vilket/vilka bolag ägandet/röstandelen utövas. Ange även om personen
        är en person i politiskt utsatt ställning (PEP).
      </Text>

      <View style={styles.table}>
        <View
          style={{
            ...styles.cell,
            fontSize: 10,
            borderRight: 'none',
            height: 17,
          }}
        >
          <Text>Ägare</Text>
        </View>
        <View
          style={{
            ...styles.cell,
            fontSize: 10,
            borderRight: 'none',
            height: 17,
          }}
        >
          <Text>Pers/Org-nr</Text>
        </View>
        <View style={{ ...styles.cell, fontSize: 10, height: 17, paddingTop: 2 }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingRight: 3,
            }}
          >
            <Text>Aktieinnehav eller roster i %</Text>
            <Text>PER</Text>
          </View>
        </View>

        <View
          style={{
            ...styles.cell,
            fontSize: 18,
            borderRight: 'none',
            borderTop: 'none',
          }}
        >
          <Text style={styles.tableText}>
            {data?.company.real_principals[0] && data?.company.real_principals[0].name}
          </Text>
        </View>
        <View
          style={{
            ...styles.cell,
            fontSize: 18,
            borderRight: 'none',
            borderTop: 'none',
          }}
        >
          <Text style={styles.tableText}>
            {data?.company.real_principals[0] && data?.company.real_principals[0].social_security_number}
          </Text>
        </View>
        <View
          style={{
            ...styles.cell,
            fontSize: 18,
            borderTop: 'none',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text style={styles.tableText}>
            {data?.company.real_principals[0] && data?.company.real_principals[0].percentage}
          </Text>
          <Text
            style={{
              border: 1,
              width: 20,
              height: 10,
              marginTop: 5,
              marginRight: 3,
            }}
          >
            {' '}
          </Text>
        </View>

        <View
          style={{
            ...styles.cell,
            fontSize: 18,
            borderRight: 'none',
            borderTop: 'none',
          }}
        >
          <Text style={styles.tableText}>
            {data?.company.real_principals[1] && data?.company.real_principals[1].name}
          </Text>
        </View>
        <View
          style={{
            ...styles.cell,
            fontSize: 18,
            borderRight: 'none',
            borderTop: 'none',
          }}
        >
          <Text style={styles.tableText}>
            {data?.company.real_principals[1] && data?.company.real_principals[1].social_security_number}
          </Text>
        </View>
        <View
          style={{
            ...styles.cell,
            fontSize: 18,
            borderTop: 'none',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text style={styles.tableText}>
            {data?.company.real_principals[1] && data?.company.real_principals[1].percentage}
          </Text>
          <Text
            style={{
              border: 1,
              width: 20,
              height: 10,
              marginTop: 5,
              marginRight: 3,
            }}
          >
            {' '}
          </Text>
        </View>

        <View
          style={{
            ...styles.cell,
            fontSize: 18,
            borderRight: 'none',
            borderTop: 'none',
          }}
        >
          <Text style={styles.tableText}>
            {data?.company.real_principals[2] && data?.company.real_principals[2].name}
          </Text>
        </View>
        <View
          style={{
            ...styles.cell,
            fontSize: 18,
            borderRight: 'none',
            borderTop: 'none',
          }}
        >
          <Text style={styles.tableText}>
            {data?.company.real_principals[2] && data?.company.real_principals[2].social_security_number}
          </Text>
        </View>
        <View
          style={{
            ...styles.cell,
            fontSize: 18,
            borderTop: 'none',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text style={styles.tableText}>
            {data?.company.real_principals[2] && data?.company.real_principals[2].percentage}
          </Text>
          <Text
            style={{
              border: 1,
              width: 20,
              height: 10,
              marginTop: 5,
              marginRight: 3,
            }}
          >
            {' '}
          </Text>
        </View>
      </View>

      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={{ fontSize: 7, width: 330 }}>
          *PEP - Person i politiskt utsatt ställning definieras som en person har eller tidigare haft viktiga offentliga
          funktioner i en stat eller i en internationell organisation eller närstående eller känd medarbetare till en
          PEP.
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 160,
            marginLeft: 19,
          }}
        >
          <Text style={{ fontSize: 8 }}>Verklig huvudman saknas</Text>
          <Text
            style={{
              border: 1,
              width: 20,
              height: 10,
              marginTop: 5,
              marginRight: 3,
            }}
          >
            {' '}
          </Text>
        </View>
      </View>
      <Text style={{ borderBottom: 1, padding: '10px 0', marginBottom: 2 }}> </Text>
      <Text style={styles.caption}>Ort, datum</Text>

      <Text style={{ borderBottom: 1, padding: '10px 0', marginBottom: 2 }}> </Text>
      <Text style={styles.caption}>Firmatecknares underskrift</Text>
    </View>
    <View style={styles.footer}>
      <View
        style={{
          borderBottom: 1,
          fontSize: 7,
          marginBottom: 10,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Text style={{ width: 250 }}>Information om verklig huvudman</Text>
        <Text>sida 1 of 1</Text>
      </View>
      <Text style={{ textAlign: 'center', fontSize: 7 }}>© GRENKELEASING AB, Norgegatan 1, 164 32 Kista</Text>
    </View>
  </Page>
);

ThirdPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
};

ThirdPage.defaultProps = {
  data: null,
};

export default ThirdPage;
