/* eslint-disable no-unused-vars */
import React from 'react';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { roamingPdfStyles } from '../../../../helpers/pdfCommonStyles';

const styles = StyleSheet.create({
  ...roamingPdfStyles,
  container: {
    ...roamingPdfStyles.container,
    height: 800,
  },

  section: {
    ...roamingPdfStyles.section,
    marginBottom: 5,
  },
});

const FivePage = () => (
  <Page style={styles.page}>
    <View style={styles.container}>
      <View style={styles.logo}>
        <Image src={logo} />
      </View>

      <View style={styles.titleContainer}>
        <Text style={styles.title}>Roamingzoner {'\n'}MMS/ Mobildata</Text>
      </View>

      <View style={styles.content}>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 1 EU/EES</Text>
          <Text style={styles.sectionText}>
            Belgien, Bulgarien, Cypern, Kroatien, Cypern, Frankrike, Franska Guyana, Franska Västindien, Grekland,
            Guadeloupe, Irland, Island, Italien, Liechtenstein, Luxemburg, Malta, Martinique, Nederländerna, Polen,
            Portugal, Réunion, Rumänien, Slovakien, Slovenien, Spanien, Tjeckien, Tyskland, Ungern, Österrike
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 1A EU/EES</Text>
          <Text style={styles.sectionText}>Danmark, Estland, Finland, Lettland, Litauen, Norge</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 2A</Text>
          <Text style={styles.sectionText}>Schweiz</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 2B</Text>
          <Text style={styles.sectionText}>Storbritannien, Gibraltar</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 3A</Text>
          <Text style={styles.sectionText}>USA, Puerto Rico</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 3B</Text>
          <Text style={styles.sectionText}>Turkiet</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 3C</Text>
          <Text style={styles.sectionText}>Kanada</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 3D</Text>
          <Text style={styles.sectionText}>Thailand</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 5A</Text>
          <Text style={styles.sectionText}>
            Benin, Botswana, Kongo (Democratic Republic), Elfenbenskusten, Etiopien, Gambia, Ghana, Kenya, Liberia,
            Mali, Marocko, Nigeria, Senegal, Sierra Leone, Tanzania, Tunisien, Uganda, Zambia
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 5B</Text>
          <Text style={styles.sectionText}>
            Afghanistan, Bahamas, Bahrain, Bangladesh, Brunei Darussalam, Mauritius, Myanmar, Nepal, Oman, Pakistan
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 6A</Text>
          <Text style={styles.sectionText}>
            Algeriet, Angola, Belize, Bhutan, Burundi, Kambodja, Kamerun, Kongo, Kuba, Djibouti, Fiji, Iran, Laos
            demokratiska folkrepublik, Libanon, Libyen, Madagaskar, Malawi, Maldiverna, Mongoliet, Moçambique, Namibia,
            Nya Kaledonien, Paraguay, Seychellerna, Sudan, Venezuela, Jemen
          </Text>
        </View>

        <View style={{ ...styles.section, marginLeft: 30 }}>
          <Text style={styles.sectionTitle}>ROAMINGZON 7A</Text>
          <Text style={styles.sectionText}>
            Australien, Indonesien, Israel, Malaysia, Filippinerna, Singapore, Sydkorea, Taiwan
          </Text>
        </View>

        <View style={{ ...styles.section, marginLeft: 30 }}>
          <Text style={styles.sectionTitle}>ROAMINGZON 7B</Text>
          <Text style={styles.sectionText}>
            Andorra, Bosnien och Hercegovina, Kina, Färöarna, Grönland, Guernsey, Hongkong, Isle of Man, Jersey, Macao,
            Moldavien, Palestina, Serbien
          </Text>
        </View>

        <View style={{ ...styles.section, marginLeft: 30 }}>
          <Text style={styles.sectionTitle}>ROAMINGZON 7C</Text>
          <Text style={styles.sectionText}>
            Albanien, Armenien, Azerbajdzjan, Georgien, Indien, Jordanien, Kazakstan, Kuwait, Mexiko, Montenegro, Nya
            Zeeland, Ukraina, Förenade Arabemiraten
          </Text>
        </View>

        <View style={{ ...styles.section, marginLeft: 30 }}>
          <Text style={styles.sectionTitle}>ROAMINGZON 7D</Text>
          <Text style={styles.sectionText}>
            Anguilla, Antigua och Barbuda, Argentina, Aruba, Barbados, Vitryssland, Bermuda, Bolivia, Brasilien,
            Brittiska Jungfruöarna, Kap Verde, Caymanöarna, Chile, Colombia, Costa Rica, Curacao, Dominica, Dominikanska
            republiken, Ecuador, Egypten, El Salvador, Grenada, Guatemala, Guyana, Haiti, Honduras, Irak,Jamaica, Japan,
            Kosovo, Kirgizistan, Makedonien, Monaco, Montserrat, Nederländska Antillerna, Nicaragua, Panama, Peru,
            Qatar, Ryssland, Saint Kitts och Nevis, Saint Lucia, Saint Vincent och Grenadinerna, Saudiarabien,
            Sydafrika, Sri Lanka, Surinam, Tadjikistan, Trinidad och Tobago, Turksoch Caicosöarna, Uruguay, Uzbekistan,
            Vietnam
          </Text>
        </View>

        <View style={{ ...styles.section, marginLeft: 30 }}>
          <Text style={styles.sectionTitle}>ROAMINGZON 8</Text>
          <Text style={styles.sectionText}>Maritime </Text>
        </View>

        <View style={{ ...styles.section, marginLeft: 30 }}>
          <Text style={styles.sectionTitle}>ROAMINGZON 9</Text>
          <Text style={styles.sectionText}>
            Burkina Faso, Centralafrikanska republiken, Komorerna, Coo Ekvatorialguinea, Falklandsöarna, Franska
            Polynesien, Gabo Guam, Guinea, Guinea-Bissau, Lesotho, Mauretanien, Niger, Papua Nya Guinea, Rwanda, Samoa,
            Sydsudan, Swaziland, Arabrepubliken Syrien, Östtimor, Tchad, Togo, Tonga, Turkmenistan, Vanuatu, Zimbabwe
          </Text>
        </View>
      </View>
    </View>
    <View style={styles.footer}>
      <Text>www.pingaway.se</Text>
    </View>
  </Page>
);

export default FivePage;
