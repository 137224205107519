import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { personalDataServiceAgreementPdfStyles } from '../../../../helpers/pdfCommonStyles';

const styles = StyleSheet.create({
  ...personalDataServiceAgreementPdfStyles,
});

// eslint-disable-next-line arrow-body-style
const FivePage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>8.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Med avseende punkten 8.1 ovan, ska Leverantören omedelbart informera Kunden om Leverantören anser att en
                instruktion strider mot GDPR eller mot andra av unionens eller medlemsstaternas dataskyddsbestäm-
                melser.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>9.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>Underbiträde</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>9.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Kunden ger härmed Leverantören ett allmänt förhandstillstånd för att anlita Underbiträde. Leverantören
                ska skriftligen informera Kunden om eventuella planer på att anlita nytt Underbiträde eller ersätta
                Underbiträde, så att Kunden har möjlighet att göra invändningar mot sådana förändringar.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>9.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Leverantören får enbart anlita Underbiträde under förutsättning att Leverantören på Kundens vägnar ingår
                ett skriftligt avtal där Underbiträdet åläggs samma skyldigheter i fråga om dataskydd som Leverantören
                fastställts i detta Biträdesavtal enligt artikel 28.3 i GDPR. Leverantören är ansvarig för att endast
                anlita Underbiträde som ger tillräckliga garantier om att genomföra lämpliga tekniska och organisa-
                toriska åtgärder på ett sådant sätt att Behandlingen uppfyller kraven i tillämpliga Dataskyddslagar.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>9.3</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                De Underbiträden som anlitas av Leverantören och som anges i Bilaga 2 har godkänts av Kunden.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>10.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>Ersättning </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>10.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Vid Leverantörens biträde enligt punkterna 4.2, 6, 8.1 och 13 (ändringar) ska, om inte annat avtalats,
                ersättning utgå på löpande räkning enligt de priser och principer som anges i Avtalet.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>11.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>Ansvar</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>11.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Om Leverantören, den som arbetar under Leverantörens ledning eller av Leverantören anlitat Underbiträde
                Behandlar Personuppgifter i strid med detta Personuppgiftsbiträdesavtal eller de lagenliga anvisningar
                som Kunden har lämnat, ska Leverantören med beaktande av de ansvarsbegränsningar som följer av Avtalet,
                ersätta Kunden för den direkta skada som Kunden har orsakats på grund av den felaktiga Behandlingen.
                Oaktat ansvarsbegränsning enligt Avtalet ska Leverantörens ansvar enligt denna punkt 11.1 alltid vara
                begränsat till ett belopp motsvarande de avgifter/den ersättning som Kunden erlagt till Leverantören
                under Avtalet under en period om tolv (12) månader innan skadan uppstod. Skulle denna paragraf bli
                aktuell innan tolv (12) månader av avtalsperioden har gått, räknas en snittdebitering per månad ut.
                Denna månatliga snittdebitering multipliceras med tolv (12) för att ge en ersättning motsvarande tolv
                (12) månader.
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

FivePage.propTypes = {};

FivePage.defaultProps = {};

export default FivePage;
