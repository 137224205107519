import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import { newAgreementStep1, newAgreementStep2, summaryPage } from '../../router/routes';
import {
  filterRow,
  findTableCell,
  initialValues,
  totalArpuCalculate,
  totalTableValue,
  totalTbCalcilate,
  serviceGroup,
  feeGroup,
} from '../../helpers/tablesValues';
import { Input, Logo, ScaleCard, Steps, StyledButton, StyledSelect } from '../../components/simple';
import { TbCardsMenu } from '../../components/complex';
import styles from './styles.module.scss';
import { selectValues } from '../../helpers/inputValues';
import { steps } from '../../helpers/stepsValues';
import { get, post, put } from '../../services/API';
import { authorization } from '../../helpers/authentication';
import ServicesTable from './Table/ServicesTable';

const NewAgreementThirdPage = () => {
  const [ramValue, setRamValue] = useState(12);
  const [switchValue, setSwitchValue] = useState(12);
  const [tableStructure, setTableStructure] = useState({});
  const [tableValues, setTableValues] = useState({});
  const [totalUtprisFirstValue, setTotalUtprisFirstValue] = useState(0);
  const [totalUtprisSecondValue, setTotalUtprisSecondValue] = useState(0);
  const [totalArpu, setTotalArpu] = useState(0);

  const [inquiryNumber, setInquiryNumber] = useState('');

  const [agreement, setAgreement] = useState(null);

  const [isSendCreditCheck, setIsSendCreditCheck] = useState(false);

  const [serviceMultipliers, setServiceMultipliers] = useState({});

  const navigate = useNavigate();
  const company = localStorage.getItem('companyId');
  const step = localStorage.getItem('step');

  const ramTableAmount = findTableCell(tableValues, 'Ping RAM', 'amount');
  const ramTablePerMonth = findTableCell(tableValues, 'Ping RAM', 'per_month');
  const switchTableAmount = findTableCell(tableValues, 'Ping Switch', 'amount');
  const switchTablePerMonth = findTableCell(tableValues, 'Ping Switch', 'per_month');

  const ramTableId = findTableCell(tableValues, 'Ping RAM', 'id');
  const switchTableId = findTableCell(tableValues, 'Ping Switch', 'id');

  const totalRamCalc = ramTableAmount && ramTableAmount * ramTablePerMonth * ramValue;
  const totalSwitchCalc = switchTableAmount && switchTableAmount * switchTablePerMonth * switchValue;

  useEffect(() => {
    if (!company) navigate(newAgreementStep1);
    if (step === '2') navigate(newAgreementStep2);
  }, [company, step]);

  useEffect(() => {
    const { servicesValues, additionalServicesValues, feeServicesValues } = tableValues;
    const totalServices = totalTableValue(servicesValues);
    const totalAdditionalServices = totalTableValue(additionalServicesValues);
    const totalFeesServices = totalTableValue(feeServicesValues);
    const totalArpuAmount = totalArpuCalculate(tableValues, totalUtprisFirstValue);

    setTotalUtprisFirstValue(totalServices + totalAdditionalServices);
    setTotalUtprisSecondValue(totalFeesServices);
    setTotalArpu(totalArpuAmount);
  }, [tableValues, totalUtprisFirstValue]);

  useEffect(() => {
    if (ramTableId) {
      setTableValues((prevState) => ({
        ...prevState,
        servicesValues: {
          ...prevState.servicesValues,
          [ramTableId]: {
            ...prevState.servicesValues[ramTableId],
            total_tb: totalRamCalc,
          },
          [switchTableId]: {
            ...prevState.servicesValues[switchTableId],
            total_tb: totalSwitchCalc,
          },
        },
      }));
    }
  }, [ramValue, switchValue]);

  const handleChange = (e, table) => {
    const { name, value } = e.target;
    setTableValues((prevState) => ({
      ...prevState,
      [table]: {
        ...prevState[table],
        [name]: {
          ...prevState[table][name],
          amount: value,
          total: value * prevState[table][name].per_month,
          total_tb: totalTbCalcilate(
            value,
            prevState[table][name].per_month,
            prevState[table][name].name,
            ramValue,
            switchValue,
          ),
        },
      },
    }));
  };

  const handlePingChange = (e, type) => {
    const { name, value } = e.target;

    setTableValues((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [name]: {
          ...prevState[type][name],
          per_month: Number(value),
          total: value * prevState[type][name].amount,
          total_tb: totalTbCalcilate(
            value,
            prevState[type][name].amount,
            prevState[type][name].name,
            ramValue,
            switchValue,
          ),
        },
      },
    }));
  };

  useEffect(() => {
    let cleanupFunction = false;
    const fetchData = async () => {
      const [services, additionalServises, fee, multipliers] = await Promise.all([
        get('/services/services', authorization()),
        get('/services/additional-services', authorization()),
        get('/services/fee', authorization()),
        get('/services/service-multipliers', authorization()),
      ]);

      setServiceMultipliers(multipliers.data);
      const quote = step === '3' && (await get(`/quotes/quotes/${localStorage.getItem('quoteId')}`, authorization()));

      if (quote.data?.ping_ram_duration > 1 && quote.data?.ping_switch_duration > 1) {
        setRamValue(quote.data.ping_ram_duration);
        setSwitchValue(quote.data.ping_switch_duration);
      }

      setAgreement(quote.data);

      setInquiryNumber(quote.data?.inquiry_number || '');

      const renderData = (initial, quoteData) => {
        if (quoteData.length > 0) {
          return initial.map((item) => {
            const currentQuote = quoteData.find((serv) => serv.id === item.id);
            // const quoteRamData = quoteData.find((serv) => serv.name === 'Ping RAM');
            // const quoteSwitchData = quoteData.find((serv) => serv.name === 'Ping Switch');
            // const perMonthFilter = (data) => {
            //   switch (data.name) {
            //     case 'Ping RAM':
            //       return quoteRamData.price_per_month;
            //     case 'Ping Switch':
            //       return quoteSwitchData.price_per_month;
            //     default:
            //       return item.price_per_month;
            //   }
            // };
            return {
              ...item,
              // price_per_month: perMonthFilter(item),
              price_per_month: currentQuote?.price_per_month ?? item?.price_per_month ?? 0,
              num_services: currentQuote?.num_services ?? item?.num_services ?? 0,
              service_duration: currentQuote?.service_duration,
            };
          });
        }
        return initial;
      };

      const servicesValues = initialValues(renderData(services.data, quote.data.services));
      const additionalServicesValues = initialValues(renderData(additionalServises.data, quote.data.add_services));
      const feeServicesValues = initialValues(renderData(fee.data, quote.data.fees));

      const servicesStructure = [
        {
          title: 'Övrigt',
          items: filterRow(services, serviceGroup.OTHER),
        },
        {
          title: 'Växelpaket',
          items: filterRow(services, serviceGroup.LICENSE),
        },
        {
          title: 'Tillägg Data',
          items: filterRow(services, serviceGroup.ADDITIONAL_DATA),
        },
        {
          title: 'Tillägg Mobila bredband',
          items: filterRow(services, serviceGroup.MOBILE_BROADBAND),
        },
      ];

      const additionalServicesStructure = [
        {
          title: 'Tilläggstjänster',
          items: filterRow(additionalServises),
        },
      ];

      const feesStructure = [
        {
          title: 'Hårdvara',
          items: filterRow(fee, feeGroup.HARDWARE),
        },
        {
          title: 'Övrigt',
          items: filterRow(fee, feeGroup.PORTS),
        },
        {
          title: 'Porteringar',
          items: filterRow(fee, feeGroup.OTHER),
        },
        {
          title: 'Nya nummer',
          items: filterRow(fee, feeGroup.NEW_NUMBERS),
        },
        {
          title: 'Slutfaktura',
          items: filterRow(fee, feeGroup.FINAL_INVOICE),
        },
        {
          title: 'Hårdvarupott',
          items: filterRow(fee, feeGroup.HARDWARE_POT),
        },
      ];
      if (!cleanupFunction)
        setTableStructure({
          servicesStructure,
          additionalServicesStructure,
          feesStructure,
        });
      if (!cleanupFunction)
        setTableValues({
          servicesValues,
          additionalServicesValues,
          feeServicesValues,
        });
    };

    fetchData();

    // eslint-disable-next-line no-return-assign
    return () => (cleanupFunction = true);
  }, []);

  const tbRamavtal = findTableCell(tableValues, 'Ping RAM', 'total_tb');
  const tbSwitch = findTableCell(tableValues, 'Ping Switch', 'total_tb');

  const handleRamChange = (e) => setRamValue(e.target.value);
  const handleSwitchChange = (e) => setSwitchValue(e.target.value);

  const servicesSubmitData =
    tableValues.servicesValues &&
    Object.keys(tableValues.servicesValues)
      .map((key) => tableValues.servicesValues[key])
      .reduce(
        (acc, item) => [
          ...acc,
          {
            service_id: item.id,
            price_per_month: item.per_month,
            num_services: item.amount || 0,
            ...(item.name === 'Ping RAM' && {
              service_duration: ramValue,
            }),
            ...(item.name === 'Ping Switch' && {
              service_duration: switchValue,
            }),
          },
        ],
        [],
      );

  const additionalServicesSubmitData =
    tableValues.additionalServicesValues &&
    Object.keys(tableValues.additionalServicesValues)
      .map((key) => tableValues.additionalServicesValues[key])
      .reduce(
        (acc, item) => [
          ...acc,
          {
            add_service_id: item.id,
            num_services: item.amount || 0,
            price_per_month: item.per_month,
          },
        ],
        [],
      );

  const feesServicesSubmitData =
    tableValues.feeServicesValues &&
    Object.keys(tableValues.feeServicesValues)
      .map((key) => tableValues.feeServicesValues[key])
      .reduce(
        (acc, item) => [
          ...acc,
          {
            fee_id: item.id,
            num_services: item.amount || 0,
            price_per_month: item.per_month,
          },
        ],
        [],
      );

  const submitForm = async () => {
    const data = await put(
      `/quotes/quotes/${localStorage.getItem('quoteId')}`,
      {
        services_data: [...servicesSubmitData],
        add_services_data: [...additionalServicesSubmitData],
        fees_data: [...feesServicesSubmitData],
        manager_id: localStorage.getItem('userId'),
        company_id: localStorage.getItem('companyId'),
        ping_ram_duration: ramValue,
        ping_switch_duration: switchValue,
        inquiry_number: inquiryNumber,
      },
      authorization(),
    );
    if (data.status === 200) {
      navigate(summaryPage);
    }
  };

  const onCreditCheck = async () => {
    if (!isSendCreditCheck) {
      const data = await post(
        `/quotes/quotes/credit-check`,
        {
          company_name: agreement?.company?.name,
          company_organization_number: agreement?.company?.organization_number,
          seller_name: agreement?.manager?.username,
          seller_email: agreement?.manager?.email,
          ping_ram_sum: tbRamavtal ?? 0,
          ping_switch_sum: tbSwitch ?? 0,
          ping_switch_duration: switchValue,
          ping_ram_duration: ramValue,
          authorized_signatory: agreement?.company?.signers?.[0]?.name,
        },
        authorization(),
      );
      setIsSendCreditCheck(true);
    }
  };

  const totalTBMedCalculate = (value, count) => {
    const amount = serviceMultipliers;
    return Math.round(amount[count] * value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.leftContent}>
          <Logo className={styles.logo} />
          <div className={styles.steps}>
            <Steps className={styles.thirdStep} steps={steps} agreement={agreement} />
            <Input
              name="inquiryNumber"
              type="text"
              onChange={(e) => {
                const regex = /^-?\d*-?\d*$/;
                const newValue = e.target.value;
                if ((regex.test(newValue) || newValue === '') && newValue.length <= 10) {
                  setInquiryNumber(newValue);
                }
              }}
              value={inquiryNumber ?? ''}
              label="Förfrågannummer"
              labelStyle={styles.label}
            />
          </div>
          <div className={styles.foretaget}>
            <h3>{agreement?.company?.name}</h3>
            <span>{agreement?.company?.organization_number}</span>
          </div>

          <div className={styles.infoSection}>
            <div className={styles.avtalspriser}>
              <h3>Avtalsnummer:</h3>
              <span>{agreement?.agreement_id}</span>
            </div>
            <div className={styles.dateSelections}>
              <div>
                <StyledSelect
                  title="Ping Ram Avtalstid:"
                  onChange={handleRamChange}
                  values={selectValues}
                  value={ramValue}
                />
              </div>
              <div>
                <StyledSelect
                  title="Ping Switch Avtalstid:"
                  onChange={handleSwitchChange}
                  values={selectValues}
                  value={switchValue}
                />
              </div>
            </div>
          </div>

          <ServicesTable
            tableStructure={tableStructure.servicesStructure}
            tableValues={tableValues.servicesValues}
            handleChange={handleChange}
            handlePingChange={(e) => handlePingChange(e, 'servicesValues')}
            tableName="servicesValues"
            name="Tjänst"
          />

          <ServicesTable
            tableStructure={tableStructure.additionalServicesStructure}
            tableValues={tableValues.additionalServicesValues}
            handleChange={handleChange}
            handlePingChange={(e) => handlePingChange(e, 'additionalServicesValues')}
            tableName="additionalServicesValues"
            name="Tjänst"
          />

          <ServicesTable
            tableStructure={tableStructure.feesStructure}
            tableValues={tableValues.feeServicesValues}
            handleChange={handleChange}
            handlePingChange={(e) => handlePingChange(e, 'feeServicesValues')}
            tableName="feeServicesValues"
            name="Engångsavgifter"
          />
        </div>
        <div className={styles.rightContent}>
          <div className={styles.scalesCards}>
            <ScaleCard
              title="Utpris"
              firstValue={totalUtprisFirstValue}
              secondValue={totalUtprisSecondValue}
              firstName="Månadskostnad"
              secondName="Engångsavgifter"
            />
            <ScaleCard title="Interna avdrag" firstName="Ping drift" firstValue="4500" />
            <ScaleCard title="ARPU" firstName="ARPU" firstValue={totalArpu} />
          </div>

          <TbCardsMenu
            switchValue={switchValue}
            ramValue={ramValue}
            tbSwitch={tbSwitch}
            tbRamavtal={tbRamavtal}
            totalTBMedCalculate={totalTBMedCalculate}
          />

          <div className={styles.button}>
            <StyledButton type="button" color="yellow" text="Credit check" onClick={onCreditCheck} />
            <StyledButton color="purple" text="Skapa avtal" onClick={submitForm} />
          </div>
        </div>
      </div>

      <Snackbar
        open={isSendCreditCheck}
        autoHideDuration={4000}
        onClose={() => setIsSendCreditCheck(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setIsSendCreditCheck(false)} severity="success" sx={{ width: '100%' }}>
          Meddelande sänt med framgång
        </Alert>
      </Snackbar>
    </div>
  );
};

export default NewAgreementThirdPage;
