import React from 'react';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { roamingPdfStyles } from '../../../../helpers/pdfCommonStyles';

const styles = StyleSheet.create({
  ...roamingPdfStyles,
  title: {
    fontSize: 31,
    fontFamily: 'Como-ExtraBold',
    marginBottom: 20,
  },

  tableHeader: {
    ...roamingPdfStyles.tableHeader,
    fontSize: 11,
  },
  container: {
    ...roamingPdfStyles.container,
    width: '100%',
  },
});
const mobileTableCreator = (roamingzon, skickaMMS, taEmotMMS, GPRSData, volume) => ({
  roamingzon,
  skickaMMS,
  taEmotMMS,
  GPRSData,
  volume,
});

const mobileTableValues = [
  mobileTableCreator('1 - EU/EES', 'pris per MB', 'pris per MB', 'Ingår*', '-'),
  mobileTableCreator('1a - EU/EES', 'pris per MB', 'pris per MB', 'Ingår*', '-'),
  mobileTableCreator('2a', 'pris per MB', 'pris per MB', '4', '169 kr / 1 GB'),
  mobileTableCreator('2b', 'pris per MB', 'pris per MB', '4', '169 kr / 1 GB'),

  mobileTableCreator('3a, 3d', 'pris per MB', 'pris per MB', '4', '169 kr / 1 GB'),
  mobileTableCreator('3b, 3c', 'pris per MB', 'pris per MB', '4', '169 kr / 1 GB'),

  mobileTableCreator('5a, 5b', 'pris per MB', 'pris per MB', '4', '269 kr / 300 MB'),

  mobileTableCreator('6a', 'pris per MB', 'pris per MB', '80', '269 kr / 10 MB'),

  mobileTableCreator('7a, 7b, 7c, 7d', 'pris per MB', 'pris per MB', '4', '169 kr / 500 MB'),

  mobileTableCreator('8', 'pris per MB', 'pris per MB', '8', '269 kr / 100 MB'),

  mobileTableCreator('9', 'pris per MB', 'pris per MB', '80', 'N/A'),
];

const SecondPage = () => (
  <Page style={styles.page}>
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>
      </View>

      <View style={styles.containerWrapper}>
        <View style={styles.tableContainer}>
          <Text style={styles.tableTitle}>MMS/ MOBILDATA - Daily Cost Control</Text>
          <View>
            <View style={styles.tableHeader}>
              <Text style={{ ...styles.cell, width: 100 }}>Roamingzon</Text>
              <Text style={{ ...styles.cell, width: 100 }}>Skicka MMS</Text>
              <Text style={{ ...styles.cell, width: 100 }}>Ta emot MMS</Text>
              <View style={{ ...styles.cell, width: 100 }}>
                <Text>GPRS-data pris/Mb</Text>
                <Text style={styles.cellSub}>SEK/MB</Text>
              </View>
              <View style={{ ...styles.cell, width: 100 }}>
                <Text>Surfmängd</Text>
                <Text style={styles.cellSub}>Maxpris/Maxdata</Text>
              </View>
            </View>
            <>
              {mobileTableValues.map((item) => (
                <View style={styles.row} key={item.roamingzon}>
                  <Text style={{ ...styles.cell, width: 100, paddingLeft: 18, textAlign: 'left' }}>
                    {item.roamingzon}
                  </Text>
                  <Text style={{ ...styles.cell, width: 100, paddingLeft: 20, textAlign: 'left' }}>
                    {item.skickaMMS}
                  </Text>
                  <Text style={{ ...styles.cell, width: 100, paddingLeft: 20, textAlign: 'left' }}>
                    {item.taEmotMMS}
                  </Text>
                  <Text style={{ ...styles.cell, width: 100 }}>{item.GPRSData}</Text>
                  <Text style={{ ...styles.cell, width: 100 }}>{item.volume}</Text>
                </View>
              ))}
            </>
          </View>
          <Text style={{ ...styles.tableInfo }}>
            Pris SEK exkl.moms. Debiteringsintervall: För samtliga zoner gäller debiteringsintervall på 1 Kb.{'\n'}
            Rörligt pris tills dagliga maxpriset uppnåtts, därefter gäller fri surf tills den dagliga surfmängden
            uppnås.{'\n'}
            Vid köp av mer surf gäller priserna som beskrivs i det sms du får när du anländer din utlandsdestination.
            {'\n'}
            Fler än ett köp kan göras per dag.{'\n'}
            Extra köp räknas inte in i Kostnadskontroll Data Utland.{'\n'}
            Oavsett var du befinner dig räknas surfmängden/dygn enligt svensk tid * Ingår inom Fair Use Policy (FUP)
          </Text>
        </View>
      </View>
    </View>
    <View style={styles.footer}>
      <Text>www.pingaway.se</Text>
    </View>
  </Page>
);

export default SecondPage;
