export const totalTbCalcilate = (value, amount, name, ramValue, switchValue) => {
  const calc = {
    'Ping RAM': value * amount * ramValue,
    'Ping Switch': value * amount * switchValue,
    'Enskilt nummer': value * -amount,
    Nummerserie: value * -amount,
    '1 or 10 serie fast el. mobil': value * -amount,
    '100 serie fast el. mobil': value * -amount,
  };

  return calc[name];
};

export const filterRow = (data, value) =>
  data.data
    .filter((item) => item.group === value)
    .map((item) => ({
      name: item.name,
      id: item.id,
      value: item.price_per_month,
      price_can_change: item?.price_can_change,
      prices: [...(item?.prices ?? [])],
      total_tb_name: 'total_tb',
      total_name: 'total_name',
    }));

const getPricePerMonth = (item) => {
  const parsePrice = parseInt(item.price_per_month, 10);

  if (item.prices?.length) {
    if (item?.prices?.includes(parsePrice)) {
      return parsePrice;
    }
    return item.prices[0] || 0;
  }

  return parsePrice;
};

export const initialValues = (data) =>
  data.reduce((acc, item) => {
    const pricePerMonth = getPricePerMonth(item);
    return {
      ...acc,
      [item.id]: {
        // TODO: add group
        name: item.name,
        id: item.id,
        amount: item.num_services || 0,
        per_month: pricePerMonth,
        for_arpu: item.for_arpu,
        total_tb: item.service_duration > 1 ? item.num_services * item.service_duration * pricePerMonth : 0,
        total: item.num_services * pricePerMonth || 0,
      },
    };
  }, {});

export const findTableCell = (value, name, cellName) =>
  value?.servicesValues &&
  Object.keys(value?.servicesValues)
    .map((key) => value?.servicesValues?.[key])
    .filter((item) => item?.name === name)?.[0]?.[cellName];

export const totalTableValue = (value) => value && Object.keys(value).reduce((acc, key) => acc + value[key].total, 0);

export const totalArpuCalculate = (table, totalUtprisFirstValue) => {
  const data =
    table.servicesValues &&
    Object.keys(table.servicesValues)
      .map((key) => table.servicesValues[key])
      .filter((item) => item.for_arpu)
      .reduce((acc, item) => acc + +item.amount, 0);

  if (data > 0) {
    return (totalUtprisFirstValue / data).toFixed(0);
  }
  return data;
};

// export const totalTBMedCalculate = (value, count) => {
//   const amount = {
//     12: value * 0.914,
//     24: value * 0.922,
//     36: value * 0.882,
//     48: value * 0.843,
//     60: value * 0.808,
//   };

//   return Math.round(amount[count]);
// };

export const serviceGroup = {
  OTHER: 'Other',
  LICENSE: 'License',
  ADDITIONAL_DATA: 'Additional Data',
  MOBILE_BROADBAND: 'Mobile Broadband Supplement',
};

export const feeGroup = {
  HARDWARE: 'Hardware',
  PORTS: 'Ports',
  OTHER: 'Other',
  NEW_NUMBERS: 'New numbers',
  FINAL_INVOICE: 'Final Invoice',
  HARDWARE_POT: 'Hardware Pot',
};
