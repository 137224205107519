import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { summaryPdfStyles } from '../../../../helpers/pdfCommonStyles';

import enTranslations from './translations/en.json';
import svTranslations from './translations/sv.json';

const styles = StyleSheet.create({
  ...summaryPdfStyles,
  content: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: 700,
  },
  title: {
    fontFamily: 'Como-Bold',
    fontSize: 32,
    marginTop: 40,
    marginBottom: 40,
    letterSpacing: 2,
  },
  blockTitle: {
    fontFamily: 'Como-Bold',
    fontSize: 12,
  },
  textBlock: {
    fontSize: 9,
    margin: '2px 0',
    flexShrink: 1,
    flexWrap: 'wrap',
    marginRight: 20,
    lineHeight: 1.1,
  },
  section: {
    margin: '10px 0',
  },
});

const ElevenPage = ({ data, lang }) => {
  const t = lang === 'en' ? enTranslations : svTranslations;
  const translations = t.elevenPage;
  const translationstenPage = t.tenPage;

  return (
    <Page style={styles.page} wrap>
      <View style={styles.container} wrap={false}>
        <View style={styles.logo}>
          <Image src={logo} />
          <View style={styles.agreementNumberBlock}>
            <Text style={styles.agreementNumber}>{data?.agreement_id}</Text>
            <Text style={styles.agreementNumber}>{data?.created_at}</Text>
          </View>
        </View>

        <View style={styles.content}>
          <View style={styles.block}>
            {lang === 'en' ? <Text style={styles.textBlock}>{translationstenPage.block_text_5_6}</Text> : null}
            <Text style={styles.blockTitle}>{translations.block_title_6}</Text>
            <Text style={styles.textBlock}>{translations.block_text_6_1}</Text>
          </View>

          <View style={styles.block}>
            <Text style={{ ...styles.blockTitle, marginTop: 10 }}>{translations.block_title_7}</Text>
            <Text style={styles.textBlock}>{translations.block_text_7_1}</Text>
          </View>

          <View style={styles.block}>
            <Text style={{ ...styles.blockTitle, marginTop: 10 }}>{translations.block_title_8}</Text>
            <Text style={styles.textBlock}>{translations.block_text_8_1}</Text>

            <Text style={styles.textBlock}>{translations.block_text_8_list_item_1}</Text>

            <Text style={styles.textBlock}>{translations.block_text_8_list_item_2}</Text>

            <Text style={styles.textBlock}>{translations.block_text_8_list_item_3}</Text>

            <Text style={styles.textBlock}>{translations.block_text_8_2}</Text>

            <Text style={styles.textBlock}>{translations.block_text_8_3}</Text>
          </View>

          <View style={styles.block}>
            <Text style={{ ...styles.blockTitle, marginTop: 10 }}>{translations.block_title_9}</Text>
            <Text style={styles.textBlock}>{translations.block_text_9_1}</Text>

            <Text style={styles.textBlock}>{translations.block_text_9_2}</Text>
          </View>

          <View style={styles.block}>
            <Text style={{ ...styles.blockTitle, marginTop: 10 }}>{translations.block_title_10}</Text>
            <Text style={styles.textBlock}>{translations.block_text_10_1}</Text>
          </View>

          <View style={styles.block}>
            <Text style={{ ...styles.blockTitle, marginTop: 10 }}>{translations.block_title_11}</Text>
            <Text style={styles.textBlock}>{translations.block_text_11_1}</Text>
          </View>
          <Text style={styles.textBlock}>{translations.block_text_11_2}</Text>

          <View style={styles.block}>
            <Text style={{ ...styles.blockTitle, marginTop: 10 }}>{translations.block_title_12}</Text>
            <Text style={styles.textBlock}>{translations.block_text_12_1}</Text>

            <Text style={styles.textBlock}>{translations.block_text_12_text}</Text>

            <Text style={styles.textBlock}>{translations.block_text_12_2}</Text>

            <Text style={styles.textBlock}>{translations.block_text_12_3}</Text>

            <Text style={styles.textBlock}>{translations.block_text_12_4}</Text>

            <Text style={styles.textBlock}>{translations.block_text_12_5}</Text>
          </View>

          <View style={styles.block}>
            <Text style={{ ...styles.blockTitle, marginTop: 10 }}>{translations.block_title_13}</Text>
            <Text style={styles.textBlock}>{translations.block_text_13_1}</Text>

            <Text style={styles.textBlock}>{translations.block_text_13_2}</Text>
          </View>
        </View>
      </View>

      <View style={styles.footer}>
        <View style={{ ...styles.footerName, marginBottom: 3 }}>
          <Text style={{ fontFamily: 'Como-Bold' }}>{t.common.offer} </Text>
          <Text style={{ fontFamily: 'Como-SemiBold' }}>{data?.company.name}</Text>
        </View>
        <Text style={{ fontFamily: 'Como-Medium' }}>{data?.created_at}</Text>
      </View>
    </Page>
  );
};

ElevenPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
  lang: PropTypes.string,
};

ElevenPage.defaultProps = {
  data: null,
  lang: 'sv',
};

export default ElevenPage;
