import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { summaryPdfStyles } from '../../../../helpers/pdfCommonStyles';

import enTranslations from './translations/en.json';
import svTranslations from './translations/sv.json';

const styles = StyleSheet.create({
  ...summaryPdfStyles,
  content: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: 670,
    marginTop: 10,
  },
  title: {
    fontFamily: 'Como-Bold',
    fontSize: 32,
    marginTop: 40,
    marginBottom: 40,
    letterSpacing: 2,
  },
  blockTitle: {
    fontFamily: 'Como-Bold',
    fontSize: 12,
  },
  textBlock: {
    fontSize: 9,
    margin: '3px 0',
    flexShrink: 1,
    flexWrap: 'wrap',
    marginRight: 20,
    lineHeight: 1.1,
  },
  textBlockForSeventeenPart: {
    fontSize: 9,
    flexShrink: 1,
    flexWrap: 'wrap',
    marginRight: 20,
    lineHeight: 1.1,
  },
  section: {
    margin: '10px 0',
  },
});

const TwelvePage = ({ data, lang }) => {
  const t = lang === 'en' ? enTranslations : svTranslations;
  const translations = t.twelvePage;

  return (
    <Page style={styles.page} wrap>
      <View style={styles.container} wrap={false}>
        <View style={styles.logo}>
          <Image src={logo} />
          <View style={styles.agreementNumberBlock}>
            <Text style={styles.agreementNumber}>{data?.agreement_id}</Text>
            <Text style={styles.agreementNumber}>{data?.created_at}</Text>
          </View>
        </View>

        <View style={styles.content}>
          {/* <View style={styles.block}>
            <Text style={styles.textBlock}>
              för upplåsning av operatörsbunden Hårdvara, om sådan upplåsning är nödvändig med anledning av bytet av
              Operatör.
            </Text>
          </View> */}

          <View style={styles.block}>
            <Text style={{ ...styles.blockTitle }}>{translations.block_title_14}</Text>
            <Text style={styles.textBlock}>{translations.block_text_14_1}</Text>

            <Text style={styles.textBlock}>{translations.block_text_14_list_item_1}</Text>

            <Text style={styles.textBlock}>{translations.block_text_14_list_item_2}</Text>

            <Text style={styles.textBlock}>{translations.block_text_14_list_item_3}</Text>

            <Text style={styles.textBlock}>{translations.block_text_14_2}</Text>

            <Text style={styles.textBlock}>{translations.block_text_14_3}</Text>

            <Text style={{ ...styles.blockTitle, marginTop: 10 }}>{translations.block_title_15}</Text>

            <Text style={styles.textBlock}>{translations.block_text_15_1}</Text>

            <Text style={styles.textBlock}>{translations.block_text_15_2}</Text>

            <Text style={styles.textBlock}>{translations.block_text_15_3}</Text>
          </View>

          {lang === 'sv' ? <Text style={styles.textBlock}>{translations.block_text_15_sub_info}</Text> : null}

          <Text style={styles.textBlock}>{translations.block_text_15_4}</Text>

          <Text style={styles.textBlock}>{translations.block_text_15_5}</Text>

          <Text style={styles.textBlock}>{translations.block_text_15_6}</Text>

          <Text style={styles.textBlock}>{translations.block_text_15_7}</Text>

          <Text style={styles.textBlock}>{translations.block_text_15_8}</Text>

          <Text style={styles.textBlock}>{translations.block_text_15_9}</Text>

          <Text style={styles.textBlock}>{translations.block_text_15_10}</Text>

          <Text style={styles.textBlock}>{translations.block_text_15_11}</Text>

          <View style={styles.block}>
            <Text style={{ ...styles.blockTitle, marginTop: 10 }}>{translations.block_title_16}</Text>
            <Text style={styles.textBlock}>{translations.block_text_16_1}</Text>

            <Text style={styles.textBlock}>{translations.block_text_16_2}</Text>

            <Text style={styles.textBlock}>{translations.block_text_16_3}</Text>
          </View>

          {lang === 'sv' ? (
            <View style={styles.block}>
              <Text style={{ ...styles.blockTitle, marginTop: 10 }}>{translations.block_title_17}</Text>

              <Text style={styles.textBlock}>{translations.block_text_17_1}</Text>

              <Text style={styles.textBlock}>{translations.block_text_17_2}</Text>

              <Text style={styles.textBlock}>{translations.block_text_17_3}</Text>

              <Text style={styles.textBlock}>{translations.block_text_17_4}</Text>
            </View>
          ) : null}
        </View>
      </View>

      <View style={styles.footer}>
        <View style={{ ...styles.footerName, marginBottom: 3 }}>
          <Text style={{ fontFamily: 'Como-Bold' }}>{t.common.offer} </Text>
          <Text style={{ fontFamily: 'Como-SemiBold' }}>{data?.company.name}</Text>
        </View>
        <Text style={{ fontFamily: 'Como-Medium' }}>{data?.created_at}</Text>
      </View>
    </Page>
  );
};

TwelvePage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
  lang: PropTypes.string,
};

TwelvePage.defaultProps = {
  data: null,
  lang: 'sv',
};

export default TwelvePage;
