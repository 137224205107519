import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { Journals } from '../../../assets/icons';
import LinkButton from '../LinkButton';
import { myAgreement } from '../../../router/routes';

const AgreementsCard = ({ name, margin, price, date, number, id }) => {
  const setQuoteId = () => localStorage.setItem('quoteId', id);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.logo}>
        <Journals />
      </div>
      <h3 className={styles.cardHead}>{name}</h3>
      <div className={styles.cardItems}>
        <p>{margin}</p>
        <p>{parseFloat(price)}</p>
        <p>{date}</p>
        <p>{number}</p>
      </div>
      <LinkButton path={myAgreement} color="purple" text="Se avtal" className={styles.button} onClick={setQuoteId} />
    </div>
  );
};

AgreementsCard.propTypes = {
  name: PropTypes.string,
  margin: PropTypes.string,
  price: PropTypes.string,
  date: PropTypes.string,
  number: PropTypes.number,
  id: PropTypes.string,
};

AgreementsCard.defaultProps = {
  name: '',
  margin: '',
  price: '',
  date: '',
  number: 0,
  id: '',
};

export default AgreementsCard;
