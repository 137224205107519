import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { slaPdfStyles } from '../../../../helpers/pdfCommonStyles';

import logo from '../../../../assets/images/Logo.png';

const styles = StyleSheet.create({
  ...slaPdfStyles,
  block: {
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 20,
    borderColor: '#10225c',
    padding: '20px 40px',
    marginBottom: 10,
  },
});

// eslint-disable-next-line arrow-body-style
const FirstPage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>

        <View style={styles.content}>
          <Text style={styles.text}>Kompensation enligt tabell nedan</Text>

          <View style={styles.block}>
            <View style={{ flex: 1, display: 'flex' }}>
              <Text style={{ ...styles.text, fontFamily: 'Como-Bold', margin: 0 }}>SLA STANDARD</Text>
            </View>

            <View style={{ flex: 1, display: 'flex' }}>
              <Text style={{ ...styles.text, fontFamily: 'Como-Bold', margin: 0 }}>
                Belopp motsvarande den obrukbara tjänstens fasta avgifter räknat för den tid felet varat från och med
                felet anmäldes till Frontdesk.
              </Text>
            </View>
          </View>

          <Text style={styles.text}>
            Kunden skall skriftligen begära kompensation inom trettio (30) dagar från meddelande om att tjänsten är
            otillgänglig. Vid begäran om kompensation skall kunden hänvisa till det ärendenummer som erhålls vid
            felanmälan.
          </Text>

          <Text style={styles.subTitle}>FRONTDESK ANSVARSOMRÅDE</Text>
          <Text style={styles.text}>
            Frontdesk ansvarar för fel som uppstår i Frontdesk system samt Frontdesk egna kopplingar mot internet,
            publika telenätet och Frontdesk mobilnät. Fel som uppstår till följd av problem i mobilnätet, mellan kundens
            IP-nät och Frontdesk nät, i kund placerad växel eller på publika internet ligger utanför Frontdesk
            ansvarsområde.
          </Text>

          <Text style={{ ...styles.subTitle, marginBottom: 5 }}>DEFINITIONER:</Text>

          <Text style={styles.textBlock}>
            <Text style={{ ...styles.text, fontFamily: 'Como-Bold' }}>Omfattande fel: </Text>
            Driftstörningar, inom Frontdesk ansvarsområde och nät, som omfattar den primära funktionen för användarna
            att placera eller besvara ett samtal och omöjliggör användarnas och företagets möjlighet att ta emot in-
            kommande samtal för merparten (&gt;50%) av användarna.
          </Text>

          <Text style={styles.textBlock}>
            <Text style={{ ...styles.text, fontFamily: 'Como-Bold' }}>Begränsat fel: </Text>
            Tjänstepåverkande supportärenden och fel som inte definierats som Omfattande fel.
          </Text>

          <Text style={styles.textBlock}>
            <Text style={{ ...styles.text, fontFamily: 'Como-Bold' }}>Starttid: </Text>
            Den maximala tid inom vilken Frontdesk garanterar att åtgärd påbörjas från det att ärendet har blivit
            registrerat av Frontdesk. Starttider räknas inom Servicetider.
          </Text>

          <Text style={styles.textBlock}>
            <Text style={{ ...styles.text, fontFamily: 'Como-Bold' }}>Åtgärdstid: </Text>
            Maximal tid från att Frontdesk påbörjat felavhjälpande åtgärder till dess att felet är avhjälpt.
            Åtgärdstider räknas inom Servicetider.
          </Text>

          <Text style={styles.textBlock}>
            <Text style={{ ...styles.text, fontFamily: 'Como-Bold' }}>Planerade driftavbrott: </Text>
            Otillgänglighet i tjänsten som uppstår på grund av åtgärd som utförs av Frontdesk under servicefönster.
          </Text>
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={styles.footerName}>www.frontdesk.se</Text>
      </View>
    </Page>
  );
};

FirstPage.propTypes = {};

FirstPage.defaultProps = {};

export default FirstPage;
