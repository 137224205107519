import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { personalDataServiceAgreementPdfStyles } from '../../../../helpers/pdfCommonStyles';

const styles = StyleSheet.create({
  ...personalDataServiceAgreementPdfStyles,

  text: {
    ...personalDataServiceAgreementPdfStyles.text,
    lineHeight: 1.2,
  },

  textBold: {
    ...personalDataServiceAgreementPdfStyles.textBold,
    lineHeight: 1.2,
  },

  table: {
    borderWidth: 1,
    borderColor: '#000',
  },

  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },
  tableCol_1: {
    flex: 1,
    padding: '5px 10px',
    borderRightWidth: 1,
    borderRightColor: '#000',
  },
  tableCol_2: {
    flex: 3,
    padding: '5px 10px',
  },
});

// eslint-disable-next-line arrow-body-style
const EightPage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={{ ...styles.title, marginBottom: 20 }}>
            Bilaga 1 - Instruktion om hantering av Personuppgifter
          </Text>

          <Text style={styles.text}>
            Följande instruktioner gäller för hantering av de Personuppgifter som Kunden är Personuppgiftsansvarig för.
            Utöver vad som redan framgår av detta Biträdesavtal ska Leverantören följa nedanstående instruktioner:
          </Text>

          <Text style={{ ...styles.textBold, marginTop: 10, marginBottom: 10 }}>Personuppgiftsbehandling</Text>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol_1}>
                <Text style={styles.textBold}>Kategorier av{'\n'} Personuppgifter</Text>
              </View>
              <View style={styles.tableCol_2}>
                <Text style={styles.text}>
                  Namn, e-postadress och telefonnummer är de huvudsakliga personuppgifter som behandlas. Ytterligare
                  personuppgifter kan vara titel och av kund inlagda sökord. I det fall kund använder sig av
                  samtalsinspel ning sparas även ljudupptagningar. Beroende på hur kund väljer att arbeta med tjänsten
                  kan även information om sjukledighet och semester behandlas. Bilder behandlas om kund väljer att
                  använda sig av bilder i tjänsten. Kunden kan självadministrera tjänsterna och ytterligare
                  personuppgifter kan behandlas beroende på hur Kunden populerar systemet med information.
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol_1}>
                <Text style={styles.textBold}>Kategorier av {'\n'} Registrerade</Text>
              </View>
              <View style={styles.tableCol_2}>
                <Text style={styles.text}>
                  Registrerade vars personuppgifter behandlas för Kundens räkning, är anställda hos Kunden, samt i före
                  kommande fall konsulter som för kunds räkning aktiveras i systemen.
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol_1}>
                <Text style={styles.textBold}>Ändamål</Text>
              </View>
              <View style={styles.tableCol_2}>
                <Text style={styles.text}>
                  Ändamålet med behandlingen av personuppgifter är att tillhandahålla de tjänster som omfattas av
                  Kunddens avtal med Frontdesk. Detta inkluderar behandling för tillhandahållande av tjänsternas
                  funktion (telefoni, inklusive telefonregister, meddelandetjänst, röst brevlådor, samtalsstatistik,
                  eventuella inspelningar, med mera beroende på vilka funktioner Kunden väljer att använda), support och
                  fakturering. Behandling av personuppgifter är nödvändig såväl för teknisk konfiguration och
                  upprätthållande av tjänsterna.
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol_1}>
                <Text style={styles.textBold}>Gallringstid</Text>
              </View>
              <View style={styles.tableCol_2}>
                <Text style={styles.text}>1. Kunden administrerar själv sina användare gällande borttagning.</Text>
                <Text style={styles.text}>2. Frontdesk kan assistera Kunden med borttagning.</Text>
                <Text style={styles.text}>
                  3. I enlighet med punkt 12.2 i Biträdesavtalet raderas uppgifter enligt Kundens instruktioner vid
                  avtalets slut. Dock senast 6 månader efter avtalets slut.
                </Text>
                <Text style={styles.text}>
                  4. För Kundens trygghet görs kontinuerliga backuper av systemen. Backuper sparas högst 12 månader.
                </Text>
              </View>
            </View>

            <View style={{ ...styles.tableRow, borderBottom: 'none' }}>
              <View style={styles.tableCol_1}>
                <Text style={styles.textBold}>Behandlingar</Text>
              </View>
              <View style={styles.tableCol_2}>
                <Text style={styles.text}>
                  Behandlingar utgörs av insamling, registrering, organisering, lagring, bearbetning, ändring, läsning,
                  användning och ytterligare behandlingar som behövs för att tillhandahålla avtalade tjänster till
                  Kunden.
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

EightPage.propTypes = {};

EightPage.defaultProps = {};

export default EightPage;
