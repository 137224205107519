import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { summaryPdfStyles } from '../../../../helpers/pdfCommonStyles';

import enTranslations from './translations/en.json';
import svTranslations from './translations/sv.json';
import { serviceGroup, feeGroup } from '../../../../helpers/tablesValues';

const styles = StyleSheet.create({
  ...summaryPdfStyles,
  title: {
    fontSize: 30,
    fontFamily: 'Como-ExtraBold',
    marginBottom: 5,
  },
  info: {
    fontSize: 12,
    marginBottom: 30,
    fontFamily: 'Como-Bold',
  },
  table: {
    width: '100%',
    borderBottom: 1,
    paddingBottom: 15,
    marginBottom: 10,
  },
  tableTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    fontSize: 16,
    borderBottom: 1,
    paddingBottom: 2,
    fontFamily: 'Como-Bold',
  },
  firstTitle: {
    fontSize: 12,
    marginRight: 20,
    width: 115,
  },
  section: {
    margin: '10px 0',
  },
  block: {
    marginBottom: 10,
  },
  secondTitle: {
    fontSize: 12,
    width: 115,
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    borderBottom: 1,
    padding: '2px 0',
    fontFamily: 'Como-Bold',
  },
  rowName: {
    width: 200,
    paddingLeft: 15,
  },
  cell: {
    width: 80,
    fontSize: 10,
    fontWeight: 300,
    textAlign: 'center',
  },
  sectionHeader: {
    fontSize: 12,
    width: 200,
    padding: '2px 0',
    fontFamily: 'Como-Bold',
  },
  quoteHeader: {
    fontSize: 12,
    fontFamily: 'Como-Bold',
    marginBottom: 3,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    fontWeight: 300,
    marginBottom: 3,
  },
  totalRow: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: 600,
    padding: '2px 0',
    fontFamily: 'Como-Bold',
  },
  totalSum: {
    width: 130,
  },
  tableInfo: {
    fontSize: 10,
    width: '85%',
    marginTop: 10,
  },
  footerName: {
    ...summaryPdfStyles.footerName,
    marginBottom: 3,
  },
});

const groupFilter = (data, name) => data && data.filter((item) => item.group === name && item.num_services > 0);

const sectionCreator = (data, title, fee) =>
  data &&
  data.length > 0 && (
    <View style={styles.block}>
      <Text style={styles.quoteHeader}>{title}</Text>
      {data.map((item) => (
        <View style={styles.row} key={item.id}>
          <Text style={styles.rowName}>
            {item.name} {item.service_duration > 1 ? `${item.service_duration} mån` : ''}
          </Text>
          <Text style={styles.cell}>{item.num_services}</Text>
          <Text style={styles.cell}> </Text>
          <Text style={styles.cell}>{fee && parseFloat(item.price_per_month)}</Text>
          <Text style={styles.cell}>{!fee && parseFloat(item.price_per_month)}</Text>
          <Text style={styles.cell}>{!fee && item.num_services * item.price_per_month}</Text>
        </View>
      ))}
    </View>
  );

const SevenPage = ({ data, lang }) => {
  const servicesOtherGroup = groupFilter(data?.services, serviceGroup.OTHER);
  const servicesAdditionalGroup = groupFilter(data?.services, serviceGroup.ADDITIONAL_DATA);
  const servicesLicenseGroup = groupFilter(data?.services, serviceGroup.LICENSE);
  const servicesMobileGroup = groupFilter(data?.services, serviceGroup.MOBILE_BROADBAND);
  const servicesAddGroup = groupFilter(data?.add_services);

  const feesHardwareGroup = groupFilter(data?.fees, feeGroup.HARDWARE);
  const feesPortsGroup = groupFilter(data?.fees, feeGroup.PORTS);
  const feesNumbersGroup = groupFilter(data?.fees, feeGroup.NEW_NUMBERS);
  const feesOtherGroup = groupFilter(data?.fees, feeGroup.OTHER);

  // const feesFinalInvoiceGroup = groupFilter(data?.fees, feeGroup.FINAL_INVOICE);
  // const feesHardwarePotGroup = groupFilter(data?.fees, feeGroup.HARDWARE_POT);

  const t = lang === 'en' ? enTranslations : svTranslations;
  const translations = t.sevenPage;

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
          <View style={styles.agreementNumberBlock}>
            <Text style={styles.agreementNumber}>{data?.agreement_id}</Text>
            <Text style={styles.agreementNumber}>{data?.created_at}</Text>
          </View>
        </View>
        <View>
          <Text style={styles.title}>{translations.title}</Text>
          <Text style={styles.info}>{translations.description}</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableTitle}>
            <Text style={styles.firstTitle}>{translations.table_title_1}</Text>
            <Text style={styles.secondTitle}>{translations.table_title_2}</Text>
          </View>

          <View style={styles.tableHeader}>
            <Text style={styles.rowName}> </Text>
            <Text style={styles.cell}>{translations.table_head_1}</Text>
            <Text style={styles.cell}>{translations.table_head_2}</Text>
            <Text style={styles.cell}>{translations.table_head_3}</Text>
            <Text style={styles.cell}>{translations.table_head_4}</Text>
            <Text style={styles.cell}>{translations.table_head_5}</Text>
          </View>

          <View>
            <View>
              <View style={styles.section}>
                <Text style={styles.sectionHeader}>{translations.section_head_1}</Text>
                {sectionCreator(servicesOtherGroup, translations.other)}
                {sectionCreator(servicesLicenseGroup, translations.license)}
                {sectionCreator(servicesAdditionalGroup, translations.additional_data)}
                {sectionCreator(servicesMobileGroup, translations.mobile_broadband_supplement)}
                {sectionCreator(servicesAddGroup, translations.additional_services)}
              </View>

              <View style={styles.section}>
                <Text style={styles.sectionHeader}>{translations.section_head_2}</Text>
                {sectionCreator(feesHardwareGroup, translations.hardware, true)}
                {sectionCreator(feesOtherGroup, translations.other, true)}
                {sectionCreator(feesPortsGroup, translations.ports, true)}
                {sectionCreator(feesNumbersGroup, translations.new_numbers, true)}

                {/* {sectionCreator(feesFinalInvoiceGroup, translations.final_invoice_services, true)}
                {sectionCreator(feesHardwarePotGroup, translations.hardware_services, true)} */}
              </View>
            </View>
          </View>
        </View>

        <View style={styles.totalRow}>
          <Text style={{ ...styles.rowName, fontSize: 18 }}>{translations.amount}</Text>
          <Text style={styles.cell}> </Text>
          <Text style={styles.cell}> </Text>
          <Text style={styles.cell}> </Text>
          <Text style={styles.totalSum}>
            {parseFloat(data?.one_time_fee)} {translations.total_sum}
          </Text>
          <Text style={styles.totalSum}>
            {parseFloat(data?.monthly_cost)} {translations.total_sum}
          </Text>
        </View>
        <Text style={styles.tableInfo}>{translations.table_info}</Text>
      </View>
      <View style={styles.footer}>
        <View style={styles.footerName}>
          <Text style={{ fontFamily: 'Como-Bold' }}>{t.common.offer} </Text>
          <Text style={{ fontFamily: 'Como-SemiBold' }}>{data?.company.name}</Text>
        </View>
        <Text style={{ fontFamily: 'Como-Medium' }}>{data?.created_at}</Text>
      </View>
    </Page>
  );
};

SevenPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
  lang: PropTypes.string,
};

SevenPage.defaultProps = {
  data: null,
  lang: 'sv',
};

export default SevenPage;
