import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { personalDataServiceAgreementPdfStyles } from '../../../../helpers/pdfCommonStyles';

const styles = StyleSheet.create({
  ...personalDataServiceAgreementPdfStyles,
});

// eslint-disable-next-line arrow-body-style
const FourPage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>4.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Leverantören ska utöver ovan, från och med Ikraftträdandet, bistå Kunden med att se till att
                skyldigheterna enligt Artiklarna 32-36 i GDPR fullgörs, med beaktande av typen av Behandling och den
                information som Leverantören har att tillgå.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>5.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>Sekretess</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>5.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Leverantören ska iaktta bestämmelserna i Offentlighetsoch sekretesslagen (2009:400) som tillämpas för
                sekretesskyddade uppgifter och information enligt nämnda lag.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>5.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Leverantören åtar sig att säkerställa att personer med behörighet att Behandla Personuppgifterna har
                åtagit sig att iaktta sekretess för sådan behandling eller omfattas av en lämplig lagstadgad
                tystnadsplikt.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>6.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>Registrerades rättigheter </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text} />
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Från Ikraftträdandet ska Leverantören, med tanke på behandlingens art, hjälpa Kunden genom tekniska och
                organisatoriska åtgärder, i den mån detta är möjligt, så att Kunden kan fullgöra sin skyldighet att
                svara på begäran om utövande av Registrerades rättigheter i enlighet med kapitel 111 i GDPR samt lämna
                ut Personuppgifter enligt tillämplig offentlighetsoch sekretesslag.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>7.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>Överföring av Personuppgifter utanför EU/EES </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>7.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                internationell organisation utan Kundens dokumenterade instruktion. Från och med Ikraftträdandet får
                emellertid överföring till ett tredjeland ske om det krävs enligt unionsrätten eller enligt en
                medlemsstats nationella rätt som Leverantören omfattas av, och i sådana fall ska Leverantören informera
                Kunden om det rättsliga kravet innan Personuppgifterna förs över till tredjeland, såvida sådan
                information inte är förbjuden med hänvisning till ett viktigt allmänintresse enligt denna rätt.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>7.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                tredjeland enligt Avtalet, eller om överföring av Personuppgifter är nöd vändigt för Leverantörens
                fullgörande av Avtalet, ska parterna vidta nödvändiga åtgärder för att säkerställa att överföring till
                tredjeland sker i enlighet med gällande Dataskyddslagar, exempelvis genom att användning av EUs
                standardavtalsklausuler (2010/87 /EU (Kommissionens beslut om standardavtalsklausuler för överföring av
                personuppgifter till registerförare etablerade i tredje land)).
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>8.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>Rätt till insyn</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>8.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Med verkan från Ikraftträdandet, ska Leverantören ge Kunden tillgång till all information som krävs för
                att visa att de skyldigheter som fastställs i GDPRs artikel 28 har fullgjorts samt möjliggöra och bidra
                till granskningar, inbegripet inspektioner, som genomförs av Kunden eller av en annan revisor som
                bemyndigats av Kunden.
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

FourPage.propTypes = {};

FourPage.defaultProps = {};

export default FourPage;
