import React from 'react';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../assets/images/Logo.png';
import { summaryPdfStyles } from '../../../helpers/pdfCommonStyles';

const styles = StyleSheet.create({
  ...summaryPdfStyles,
  containerWrapper: {
    width: '70%',
    margin: '0 auto',
  },
  title: {
    fontSize: 31,
    fontFamily: 'Como-ExtraBold',
    margin: '50px 0',
  },
  table: {
    marginBottom: 40,
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '97%',
    borderBottom: 1,
    paddingBottom: 2,
    marginBottom: 5,
  },
  tableTitle: {
    padding: '0 20px',
    fontFamily: 'Como-Bold',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontFamily: 'Como',
    fontSize: 14,
    margin: '5px 20px',
  },
  tableInfo: {
    fontSize: 12,
  },
  footer: {
    textAlign: 'center',
    paddingBottom: 20,
    fontSize: 12,
  },
});

const rowCreator = (id, name, value) => ({
  id,
  name,
  value,
});

const firstTable = [
  rowCreator(1, 'Öppningsavgift', '0,59 kr'),
  rowCreator(2, 'Sverige fast', '0,59 kr'),
  rowCreator(3, 'Sverige mobil', '0,59 kr'),
  rowCreator(4, 'Utland fast', '0,59 kr'),
  rowCreator(5, 'Utland mobil', '1,99 kr'),
];

const secondTable = [
  rowCreator(1, 'SMS inom Sverige', '0,59 kr'),
  rowCreator(2, 'SMS till utland', '1,59 kr'),
  rowCreator(3, 'MMS', '1,59 kr'),
];

const Trafic = () => (
  <Page style={styles.page}>
    <View style={styles.container}>
      <View style={styles.logo}>
        <Image src={logo} />
      </View>
      <View style={styles.containerWrapper}>
        <Text style={styles.title}>Trafikprislista - Pingaway</Text>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.tableTitle}>Samtalsavgift</Text>
            <Text style={styles.tableTitle}>Pris</Text>
          </View>
          <>
            {firstTable.map((item) => (
              <View style={styles.row} key={item.id}>
                <Text>{item.name}</Text>
                <Text>{item.value}</Text>
              </View>
            ))}
          </>
        </View>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.tableTitle}>Meddelanden</Text>
            <Text style={styles.tableTitle}>Pris</Text>
          </View>
          <>
            {secondTable.map((item) => (
              <View style={styles.row} key={item.id}>
                <Text>{item.name}</Text>
                <Text>{item.value}</Text>
              </View>
            ))}
          </>
        </View>

        <Text style={styles.tableInfo}>
          Priserna är angivna exklusive moms, per minut och gäller dygnet runt och gäller ej satelittelefoner,
          operatörstjänster, servicenummer, betaltjänster etc. Debiteringsintervall 60 sekunder där inget annat anges.
        </Text>
      </View>
    </View>
    <View style={styles.footer}>
      <Text>
        <a href="www.pingaway.se">www.pingaway.se</a>
      </Text>
    </View>
  </Page>
);

export default Trafic;
