import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { ErrorMessage, StyledButton, Input } from '../../../components/simple';
import styles from './styles.module.scss';
import { forgotPasswordPage, mainPage } from '../../../router/routes';
import { post } from '../../../services/API';

const LoginForm = () => {
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      const data = await post('auth/', values);
      if (data.status === 200) {
        localStorage.setItem('token', data.data.token);
        navigate(mainPage);
      } else {
        setError(true);
      }
    },
  });
  return (
    <div className={styles.loginForm}>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <Input name="email" type="text" onChange={formik.handleChange} value={formik.values.email} label="E-post:" />
        <Input
          name="password"
          type="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          label="Lösenord:"
        />
        {error && <ErrorMessage text="Användare med dessa uppgifter hittades inte" />}
        <StyledButton type="submit" color="yellow" text="Logga in" className={styles.button} />
      </form>
      <Link to={forgotPasswordPage} className={styles.link}>
        Glömt lösenord?
      </Link>
    </div>
  );
};

export default LoginForm;
