import React from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'ArialRegular',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#000',
  },
  container: {
    height: 830,
  },
  content: {
    fontSize: 7,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '40%',
  },
  title: {
    color: '#2072f5',
    fontSize: 12,
    fontFamily: 'ArialBoldItalic',
    marginTop: 15,
    marginLeft: 44,
    marginBottom: 20,
  },
  block: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8,
  },
  blockText: {
    flexWrap: 'wrap',
  },
  number: {
    paddingLeft: 20,
    marginRight: 18,
  },
  blockHeader: {
    color: '#2072f5',
    fontSize: 8,
  },
  footer: {
    fontSize: 8,
    color: 'grey',
    width: 250,
    marginLeft: 30,
  },
});

const SecondPage = () => (
  <Page style={styles.page}>
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>CONTRACT ASSIGNMENT OFFICE DIRECT</Text>
      </View>

      <View style={{ marginLeft: 44 }}>
        <Text style={{ fontSize: 8, color: 'grey', marginBottom: 5 }}>
          CAOD19SE01 CAOD – HYRESAVTAL FÖR NÄRINGSIDKARE
        </Text>
        <Text style={{ fontSize: 14, marginBottom: 10 }}>ALLMÄNNA AVTALSVILLKOR</Text>
      </View>

      <View style={styles.content}>
        <View style={styles.block}>
          <Text style={styles.number}>1.</Text>
          <View>
            <Text style={styles.blockHeader}>
              HYRESSTART, SKYLDIGHET ATT REKLAMERA FEL, GODKÄNNANDE AV HYRESFÖREMÅLET
            </Text>
            <Text style={styles.blockText}>
              Den fasta ordinarie avtalstiden påbörjas den första dagen i det kalenderkvartal, eller månad om månatlig
              betalning avtalats, efter leverans av Hyresföremålet. Levereras Hyresföremålet före denna dag, ska HT
              betala en användaravgift motsvarande 1/30 av bashyran per dag för interimsperioden. HT ska motta och
              undersöka Hyresföremålet och omgående reklamera fel. HT ska endast godkänna leverans av Hyresföremålet när
              HT konstaterat att leveransen är komplett och i ett avtalsenligt skick.
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <Text style={styles.number}>2.</Text>
          <View>
            <Text style={styles.blockHeader}>FÖRLÄNGNING, HYRESTIDENS SLUT, RETUR AV HYRESFÖREMÅLET</Text>
            <Text style={styles.blockText}>
              Avtalet förlängs automatiskt med tolv månader om det inte sägs upp skriftligen av en av parterna minst sex
              månader före utgången av den ordinarie hyrestiden eller förlängningstiden. Vid uppsägning av avtalet –
              även före överenskommet datum – ska HT på HTs bekostnad och risk returnera Hyresföremålet i funktionellt
              och avtalsenligt skick till Leverantören. Hyresföremålet returneras till Leverantörens registrerade adress
              eller till av Leverantören angiven adress. HT ansvarar för att all data som tillhör HT raderas. Om
              Hyresföremålet inte returneras i tid, har Leverantören rätt, men inte skyldighet, att låta genomföra
              återlämningen av Hyresföremålet på HTs bekostnad.
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <Text style={{ ...styles.number, marginRight: 22 }}> </Text>
          <View>
            <Text style={styles.blockText}>
              Sägs avtalet upp på grund av omständighet som HT svarar för ska HT betala ersättning till Leverantören
              enligt nedan inom 14 dagar från mottagande av uppsägningen och Leverantörens ersättningskrav:
            </Text>
          </View>
        </View>

        <View style={{ ...styles.block, marginBottom: 0 }}>
          <Text style={{ ...styles.number, marginRight: 22 }}>-</Text>
          <View>
            <Text style={styles.blockText}>
              samtliga förfallna oreglerade avgifter och andra betalningar enligt detta avtal, inklusive samtliga
              kostnader för återtagande av Hyresföremålet, samt ränta på förfallna belopp med [2 %] per påbörjad månad
              fr.o.m. förfallodagen;
            </Text>
          </View>
        </View>
        <View style={styles.block}>
          <Text style={{ ...styles.number, marginRight: 22 }}>-</Text>
          <View>
            <Text style={styles.blockText}>summan av alla kvarvarande betalningar enligt avtalet,</Text>
          </View>
        </View>
        <View style={styles.block}>
          <Text style={{ ...styles.number, marginRight: 22 }}> </Text>
          <View>
            <Text style={styles.blockText}>
              Från ovanstående ersättning ska avdrag göras med det belopp som Grenke, efter avdrag för kostnader i
              samband med återtagande och försäljning av Hyresföremålet, erhåller vid en försäljning av Hyresföremålet.
              Avlider HT har HTs arvingar rätt att säga upp hyresavtalet till slutet av det kalenderkvartal (eller
              kalendermånad om månatlig betalning avtalats). Sägs hyresavtalet upp har Leverantören rätt att kräva
              ersättning för förtida uppsägning av hyresavtalet enligt ovan.
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <Text style={styles.number}>3.</Text>
          <View>
            <Text style={styles.blockHeader}>LEVERANS OCH HTs RÄTTIGHETER</Text>
            <Text style={styles.blockText}>
              HT svarar för kostnaderna för leverans av Hyresföremålet till HT. Grenke överlåter samtliga rättigheter
              avseende avtalsenlig leverans till HT med undantag av rätten till återbetalning av köpeskillingen. HT
              ansvarar för att dessa rättigheter utövas.
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <Text style={styles.number}>4.</Text>
          <View>
            <Text style={styles.blockHeader}>
              RISKEN FÖR FÖRLUST AV HYRESFÖREMÅLET GENOM OLYCKSFALL, FÖRSTÖRELSE ELLER FÖRSÄMRING OCH SAMT UTEBLIVEN
              BETALNING
            </Text>
            <Text style={styles.blockText}>
              HT bär risken för förlust av Hyresföremålet genom olycks-fall, förstörelse eller försämring. Som ägare av
              Hyresföremålet har HG tecknat en allmän egendomsförsäkring med en auktoriserad försäkringsgivare i
              enlighet med Allmänna Villkor för Elektronikförsäkring (se Allmänna Villkor för Allmän Egendomsförsäkring,
              tillhandahålls av HG eller på www.grenkeleasing.se/foersaekring). HT bär kostnaderna för att
              Hyresföremålet omfattas av denna försäkring. HT har rätt – och på begäran av HG också skyldighet – att när
              som helst på egen bekostnad teckna egen egendomsförsäkring med ett försäkringsbolag HT själv valt.
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <Text style={styles.number}>5.</Text>
          <View>
            <Text style={styles.blockHeader}>ANVÄNDNING, KOSTNADER OCH REPARATIONER</Text>
            <Text style={styles.blockText}>
              HT ska följa Leverantörens och tillverkarens rekommendationer samt myndighetskrav avseende användningen av
              Hyresföremålet och ska betala samtliga avgifter och kostnader avseende användning och innehav av
              Hyresföremålet och övriga avgifter till Leverantören. HT ska hålla Hyresföremålet i avtalsenligt och
              funktionellt skick (innefattande inköp av rekommenderade uppdateringar) på HTs bekostnad till dess
              Hyresföremålet returneras till Leverantören; dessutom ska HT ersätta Leverantören för eventuella krav från
              tredje parter avseende installation, drift eller ägande av Hyresföremålet. Hyresföremålet får inte
              överlämnas till Leverantören eller någon tredje part utom i reparationssyfte och i så fall endast så länge
              det är nödvändigt.
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <Text style={styles.number}>6.</Text>
          <View>
            <Text style={styles.blockHeader}>ANSVAR, GRENKES RÄTTIGHETER OCH SKYLDIGHETER VID FEL</Text>
            <Text style={styles.blockName}>Allmänt</Text>
            <Text style={styles.blockText}>
              Grenke har överlåtit sina samtliga rättigheter avseende Hyresföremålet gentemot Leverantören enligt
              överlåtelseavtalet med Leverantören till HT. Vid fel eller garantianspråk ska HT genast göra dessa
              rättigheter gällande mot Leverantören. Grenke har inget ansvar för Leverantörens fullgörande av dess
              åtaganden avseende Hyresföremålet. Fel eller brist i Hyresföremålet berättigar inte HT till innehållande
              av eller avdrag på betalningar enligt detta avtal. HT är inte heller berättigad till uppskov med dessa
              betalningar.
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <Text style={{ ...styles.number, marginRight: 23 }}> </Text>
          <View>
            <Text>Mjukvara</Text>
            <Text style={styles.blockText}>
              Innehåller Hyresföremålet mjukvara ska HT ingå licensavtal med Leverantören och efterleva samtliga villkor
              i licensavtalet, innefattande villkor angående återlämnande av mjukvaran. Nyttjanderätten till mjukvaran
              upphör när detta avtal upphör om inte något annat datum framgår av licensavtalet. Grenke har inget ansvar
              för HTs nyttjande av mjukvaran. Grenke ansvarar inte i något fall om Leverantören, t.ex. på grund av
              obestånd eller annat, inte kan fullgöra sina åtaganden enligt licensavtalet. HT ska betala samtliga
              avgifter enligt detta avtal även om bristande uppfyllelse från licensinnehavaren innebär att HT begränsas
              i nyttjandet av Hyresföremålet. HT ska ersätta Leverantören för samtliga kostnader som kan drabba
              Leverantören eller Grenke p.g.a. HTs nyttjande av mjukvaran eller brott mot licensavtalet.
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <Text style={{ ...styles.number, marginRight: 23 }}> </Text>
          <View>
            <Text>Service & Underhåll</Text>
            <Text style={styles.blockText}>
              Grenke ansvarar inte för garanti, service, underhåll, förbrukning och tjänster som ingår i hyran eller
              Hyresföremålets skick eller lämplighet för HT. HT äger inte innehålla någon betalning till Leverantören
              pga. fel eller brist i Hyresföremålet eller med anledning av fel eller brist i Leverantörens fullgörande
              av sina åtaganden.
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <Text style={styles.number}>7.</Text>
          <View>
            <Text style={styles.blockHeader}>ÖVERLÅTELSE AV RÄTTIGHETER OCH SKYLDIGHETER, REFINANSIERING </Text>
            <Text style={styles.blockText}>
              HT får endast överlåta eller pantsätta sina respektive rättigheter och skyldigheter enligt detta avtal med
              Grenkes föregående skriftliga samtycke. Grenke får överlåta sina respektive förpliktelser enligt detta
              avtal till en refinansiär, förutsatt att överlåtelsen inte medför några legala eller ekonomiska nackdelar
              för HT. Parterna är överens om följande försiktighetsåtgärder för att skydda refinansiären vid Grenkes
              insolvens: har refinansiären rätt att nyttja Hyresföremålet genom hyra är HT skyldig att antingen på nytt
              ingå detta hyresavtal eller att istället fortsätta detta avtal med refinansiären eller en tredje part som
              refinansiären anvisar. HT ska inte försättas i en sämre legal eller ekonomisk situation än den position HT
              skulle ha varit i om inget insolvensförfarande inletts.
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <Text style={styles.number}>8.</Text>
          <View>
            <Text style={styles.blockHeader}>FAKTURERING OCH BETALNING </Text>
            <Text style={styles.blockText}>
              Betalning av avgifter hänförliga till uthyrningen av Hyresföremålet och Service & Underhåll sker till
              Leverantören till av Leverantören anvisat bankkonto. Leverantören tillhandahåller fakturor för förfallna
              belopp enligt hyresavtalet. Grenke kan när som helst under avtalstiden meddela att betalning av
              hyresavgifter och andra avgifter hänförliga till uthyrningen av Hyresföremålet istället ska ske till av
              Grenke anvisat konto.
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <Text style={styles.number}>9.</Text>
          <View>
            <Text style={styles.blockHeader}>UTBYTE AV HYRESFÖREMÅLET OCH FÖRTIDA UPPSÄGNING</Text>
            <Text style={styles.blockText}>
              HT och Leverantören kan med Grenkes föregående skriftliga samtycke särskilt överenskomma att helt eller
              delvis byta ut Hyresföremålet.
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <Text style={{ ...styles.number, marginRight: 13 }}>10.</Text>
          <View>
            <Text style={styles.blockHeader}>ÄNDRINGAR OCH TILLÄGG</Text>
            <Text style={styles.blockText}>
              Ändringar eller tillägg till detta avtal kan endast avtalas direkt mellan Grenke och HT. Avtal mellan HT
              och Leverantören ger inte Grenke några rättigheter eller skyldigheter.
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <Text style={{ ...styles.number, marginRight: 13 }}>11.</Text>
          <View>
            <Text style={styles.blockHeader}>PERSONUPPGIFTER</Text>
            <Text style={styles.blockText}>
              GRENKELEASING AB som ansvarig för databehandling och datainsamling är skyldig att tillhandahålla
              information om databehandling och datainsamling till kunden. GRENKELEASING AB uppfyller denna skyldighet
              genom att tillhandahålla ”uppgifter om personuppgifter”. Dataskydds- informationen är tillgänglig i sin
              nuvarande version på in www.grenkeleasing.se/dataskydd. Dessutom kan den begäras via e- post, telefon
              eller post enligt informationen på webbsidan. Dataskyddsansvarig kan kontaktas via e-post på
              dataskydd@grenke.se eller på adressen som anges på företagets webbsida. GRENKE är personuppgiftsansvarig
              för insamlade personuppgifter.
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <Text style={{ ...styles.number, marginRight: 13 }}>12.</Text>
          <View>
            <Text style={styles.blockHeader}>TVISTER</Text>
            <Text style={styles.blockText}>
              Svensk materiell rätt ska gälla för detta avtal. Tvister som uppstår i anledning av detta avtal ska
              avgöras i första instans av Attunda tingsrätt.
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Text>
          GRENKELEASING AB Isafjordsgatan 31. 164 40 Kista . Tel. +46 8 444 16-70 . E-mail service@grenke.se Org: nr:
          556649-3929. Moms reg. nr: SE556649392901
        </Text>
      </View>
    </View>
  </Page>
);

export default SecondPage;
