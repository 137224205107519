import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { personalDataServiceAgreementPdfStyles } from '../../../../helpers/pdfCommonStyles';

const styles = StyleSheet.create({
  ...personalDataServiceAgreementPdfStyles,
});

// eslint-disable-next-line arrow-body-style
const SixPage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>11.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Leverantörens ersättningsskyldigheter avseende krav och skador enligt denna punkt 11 gäller under
                förutsättning att i) Kunden utan onödigt dröjsmål skriftligen underrättar Leverantören om krav som
                framställts mot Kunden; och ii) Kunden låter Leverantören kontrollera försvaret av kravet och ensam
                fatta beslut om eventuell förlikning.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>11.3</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Kunden skall hålla Leverantören skadeslös och ersätta Leverantören för all skada som Leverantören lider
                till följd av Kundens agerande i strid med Avtalet eller detta Biträdesavtal eller Kundens Behandling av
                Personuppgifter i strid med tillämpliga Dataskyddslagar.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>11.4</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Denna punkt 11 ska gälla även efter Biträdesavtal upphörande i enlighet med punkt 12 nedan.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>12.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>Avtalstid och åtgärder vid upphörande</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>12.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Biträdesavtalet gäller från dess undertecknande och så länge som Leverantören Behandlar Personuppgifter
                för Kundens räkning.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>12.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                När Leverantörens tillhandahållande av behandlingstjänster har avslutats enligt detta Biträdesavtal, ska
                Leverantören, beroende på vad Kunden väljer, radera och/eller återlämna alla Personuppgifter till Kunden
                samt radera befintliga kopior såvida inte lagring av Personuppgifterna krävs enligt unionsrätten eller
                medlemsstaternas nationella rätt.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>13.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>Ändringar i Biträdesavtalet </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>13.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Om Dataskyddslagarna ändras under giltighetstiden för Biträdesavtalet, eller om Tillsynsmyndighet
                utfärdar riktlinjer, beslut eller föreskrifter kring tillämpningen av Dataskyddslagarna som föranleder
                att detta Biträdesavtal inte uppfyller de krav som ställs på ett personuppgiftsbiträdesavtal enligt
                Dataskyddslagar, ska respektive part ha rätt att begära ändringar av detta Biträdesavtal för att
                tillgodose sådana nya, ändrade eller förtydligade krav.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>13.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Leverantören har rätt till skälig ersättning för ökade kostnader för att uppfylla andra än mindre
                ändringar av sina åtaganden enligt Avtalet till följd av detta Biträdesavtal som meddelas Leverantören
                efter ingåendet av Biträdesavtalet och som Leverantören inte insåg eller bort inse skulle gälla vid
                Behandling av Personuppgifter. Om ändringen omfattar fler kunder med motsvarande tjänster än Kunden, ska
                Leverantörens kostnader fördelas mellan alla kunder. Leverantören ska utan dröjsmål, dock senast tre
                månader efter det att Kunden har meddelat Leverantören förändrade eller nya instruktioner, meddela
                Kunden vilken ersättning Leverantören anser sig ha rätt till med stöd av denna punkt 13.2. Om sådant
                meddelande inte lämnas inom denna tid, har Leverantören inte rätt till någon ersättning.
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

SixPage.propTypes = {};

SixPage.defaultProps = {};

export default SixPage;
