import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { personalDataServiceAgreementPdfStyles } from '../../../../helpers/pdfCommonStyles';

const styles = StyleSheet.create({
  ...personalDataServiceAgreementPdfStyles,
});

// eslint-disable-next-line arrow-body-style
const SevenPage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>13.3</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Ändringar av och tillägg till detta Biträdesavtal ska för att vara bindande upprättas skriftligen och
                vara behörigen undertecknade av parterna.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>14.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>Övrigt</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>14.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Vid motstridighet mellan bestämmelserna i Avtalet och detta Biträdesavtal ska bestämmelserna i
                Biträdesavtalet äga företräde i fråga om Behandling av Personuppgifter.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>14.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Svensk lag, med undantag av lagvalsregler, ska tillämpas på Leverantörens Behandling av Personuppgifter
                enligt detta Biträdesavtal.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>14.3</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Tvister som uppstår i anledning av detta Biträdesavtal ska lösas i enlighet med
                tvistlösningsbestämmelsen i Avtalet.
              </Text>
            </View>
          </View>

          <Text style={{ ...styles.text, textAlign: 'center', margin: '20px 0' }}>****</Text>

          <Text style={{ ...styles.text }}>
            Detta Biträdesavtal signeras digitalt av behöriga företrädare för respektive part.
          </Text>
        </View>
      </View>
    </Page>
  );
};

SevenPage.propTypes = {};

SevenPage.defaultProps = {};

export default SevenPage;
