import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { personalDataServiceAgreementPdfStyles } from '../../../../helpers/pdfCommonStyles';

const styles = StyleSheet.create({
  ...personalDataServiceAgreementPdfStyles,
});

// eslint-disable-next-line arrow-body-style
const ThirdPage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold} />
            </View>

            <View style={styles.col_2}>
              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>”Tredje land”</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>
                    avser ett land utanför det Europeiska ekonomiska samarbetsområdet (EES).
                  </Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>”Underbiträde”</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>
                    avser det andra Personuppgiftsbiträde som Behandlar Personuppgifter som underleverantör åt
                    Leverantören (vilket innefattar men inte är begränsat till Leverantörens koncernbolag).
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>2.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Eventuella övriga uttryck eller definitioner med stor begynnelsebokstav som används i detta
                Biträdesavtal ska, om inget annat uttryckligen anges, ha den innebörd och betydelse som framgår i första
                hand av Dataskyddslagarna och annars av Avtalet om inte omständigheterna uppen barligen talar för annan
                tolkningsordning.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>3.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>Ansvar och instruktion</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>3.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                De kategorier av Personuppgifter som Behandlas av Frontdesk på upp drag av Kunden, ändamålen med
                behandlingen, behandlingens varaktighet och kategorier av registrerade beskrivs i Bilaga 1 (Instruktion
                om hantering av Personuppgifter).
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>3.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Frontdesk får endast Behandla Personuppgifterna i enlighet med Kundens dokumenterade instruktioner
                enligt Bilaga 1. Leverantören får inte Behandla Personuppgifter för andra ändamål än dem som Leveran-
                tören anlitats för under Avtalet. Från och med Ikraftträdandet får emellertid annan Behandling ske i den
                mån Behandling krävs enligt unionsrätten eller enligt en medlemsstats nationella rätt som Leverantören
                omfattas av, och i sådana fall ska Leverantören informera Kunden om det rättsliga kravet innan
                Personuppgifterna Behandlas, såvida sådan information inte är förbjuden med hänvisning till ett viktigt
                allmänintresse enligt denna rätt.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>3.3</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Leverantören, leverantörens underbiträden, personer som arbetar under Leverantörens överinseende, och
                som får tillgång till Personuppgifter, får endast Behandla Personuppgifter i enlighet med Kundens
                dokumenterade instruktioner. Såvida det inte finns en skyldighet att Behandla dem på annat sätt enligt
                unionsrätten eller medlemsstaternas nationella rätt.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>3.4</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Alla misstänkta eller faktiska personuppgiftsincidenter ska omedelbart rapporteras till Kunden.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>4.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>Säkerhet </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>4.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Leverantören åtar sig att vidta de åtgärder som krävs för att uppfylla 31 § 1 st. Personuppgiftslagen
                (1998:204) och, senast vid Ikraftträdandet, vidta de åtgärder som krävs enligt Artikel 32 i GDPR.
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

ThirdPage.propTypes = {};

ThirdPage.defaultProps = {};

export default ThirdPage;
