import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { slaPdfStyles } from '../../../../helpers/pdfCommonStyles';

import logo from '../../../../assets/images/Logo.png';
import img from '../../../../assets/images/sla_img_2.png';

const styles = StyleSheet.create({
  ...slaPdfStyles,
});

// eslint-disable-next-line arrow-body-style
const SecondPage = () => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>

        <View style={styles.content}>
          <Text style={styles.text}>
            Resultatet av ovanstående är en historiskt mycket driftsäker tjänst (Upptid 99,9%), vilken idag assisterar
            250.000 användare fördelat över 10.000 företag i Norden med affärskritisk daglig telefonioch företags-
            kommunikation.
          </Text>

          <Text style={styles.subTitle}>SERVICENIVÅER</Text>
          <Text style={styles.text}>
            För att möjliggöra kontinuerliga förändringar av specifik kunds konfiguration och användarinställningar
            erbjuder Frontdesk möjligheten att i stor utsträckning för ändra tjänstens inställningar och användare via
            webportal. Denna tjänst kan nås 24/7/365 av utpekad behörig administratör hos er. Förändringar utförs i
            realtid utan krav på direktkontakt med Frontdesk personal.
          </Text>

          <Image src={img} />

          <Text style={styles.text}>
            Som komplement till ovan arbetar Frontdesk med utpekad leveranskoordinator. Er personliga kontakt hjälper er
            under hela leveransprojektet med konfiguration och setup. Portering av befintliga nummer, samt utbildning av
            administratörer, telefonister och användare. Därefter lämnas kunden över till vår kundsrevice som finns på
            plats alla vardagar för att hjälpa till med löpande service & Support under avtalstid.
          </Text>
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={styles.footerName}>www.frontdesk.se</Text>
      </View>
    </Page>
  );
};

SecondPage.propTypes = {};

SecondPage.defaultProps = {};

export default SecondPage;
