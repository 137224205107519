import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { personalDataServiceAgreementPdfStyles } from '../../../../helpers/pdfCommonStyles';

const styles = StyleSheet.create({
  ...personalDataServiceAgreementPdfStyles,
});

// eslint-disable-next-line arrow-body-style
const FirstPage = ({ data }) => {
  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={{ ...styles.title, marginBottom: 10 }}>PERSONUPPGIFTSBITRÄDESAVTAL</Text>

          <Text style={styles.text}>
            Detta personuppgiftsbiträdesavtal (”<Text style={styles.textBold}>Biträdesavta/-let</Text>”) har denna dag
            träffats mellan:
          </Text>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>(1)</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                {data?.company?.name} , org nr {data?.company?.organization_number}, {data?.company?.postal_address} (”
                <Text style={styles.textBold}>Kunden</Text>”); och Frontdesk
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>(2)</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Frontdesk Nordic AB, org nr 556907-3256, Drakegatan 10, 412 50 Göteborg (”
                <Text style={styles.textBold}>Leverantören</Text>” och/eller ”Frontdesk”).
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>1.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>Allmänt</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>1.1</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Detta Biträdesavtal utgör en integrerad del av Kundens ramavtal med Frontdesk avseende
                kommunikationstjänster (” <Text style={styles.textBold}>Avtal-/et</Text>”). Frontdesk kommer vid
                fullföljandet av Avtalet att Behandla Personuppgifter för Kundens räkning så som Kundens
                Personuppgiftsbiträde.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>1.2</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Syftet med detta Biträdesavtal är att reglera Frontdesks Behandling av Personuppgifter för Kundens
                räkning. Kunden är Personuppgiftsansvarig för Behandlingen av Personuppgifterna enligt detta
                Biträdesavtal.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.text}>1.3</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.text}>
                Om någon annan eller någon annan tillsammans med Kunden under avta Istiden blir Personuppgiftsansvarig
                för de aktuella Personuppgifter na ska Kunden informera Leverantören om detta.
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_1}>
              <Text style={styles.textBold}>2.</Text>
            </View>

            <View style={styles.col_2}>
              <Text style={styles.textBold}>Definitioner</Text>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>”Avtalet”</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>avser det avtal som anges i punkten 1.1 ovan.</Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>”Behandling”</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>
                    avser behandling såsom begreppet bestäms enligt vid var tid gällande Dataskyddslagar.
                  </Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>”Biträdesavtal/-et”</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>avser detta personuppgiftsbiträdesavtal.</Text>
                </View>
              </View>

              <View style={styles.sub_row}>
                <View style={styles.sub_col_1}>
                  <Text style={styles.textBold}>”Dataskyddslagar”</Text>
                </View>
                <View style={styles.sub_col_2}>
                  <Text style={styles.text}>
                    avser vid var tid gällande lag eller förordning som ska tillämpas på Behandling av Personupp gifter
                    vilket innefattar men inte är begränsat till Personuppgiftslagen (1998:204) och från den 25 maj 2018
                    Europaparlamentets och Rådets Förordning (EU) 2016/679 om skydd för fysiska personer med avseende på
                    behandling av personuppgifter och om det fria flödet av sådana uppgifter (”GDPR”) vilken ersätter
                    Personuppgiftslagen (1998:204); samt Tillsynsmyndighets bindande riktlinjer, beslut och föreskrifter
                    samt tillkommande lokal anpassning och reglering avseende dataskydd.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

FirstPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
};

FirstPage.defaultProps = {
  data: null,
};

export default FirstPage;
