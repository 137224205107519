import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Frontdesk.png';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'ArialRegular',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: '#10225c',
  },
  container: {
    width: '90%',
    margin: '0 auto',
  },
  logo: {
    width: 120,
    marginLeft: 'auto',
    marginTop: 15,
    color: 'grey',
    fontSize: 16,
    textAlign: 'center',
    position: 'absolute',
    right: 0,
  },
  title: {
    fontSize: 18,
    fontFamily: 'ArialBold',
    marginTop: 20,
  },
  input: {
    border: 1,
    width: 200,
  },
  label: {
    fontSize: 6,
    marginLeft: 5,
    marginTop: 1,
  },
  text: {
    fontFamily: 'Helvetica',
    fontSize: 14,
    paddingTop: 2,
    paddingLeft: 5,
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    fontFamily: 'ArialBold',
    paddingLeft: 5,
    paddingBottom: 1,
  },
  sectionName: {
    width: '50%',
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  squares: {
    height: 25,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  square: {
    width: 15,
    height: 15,
    border: 1,
  },
  inputValues: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    padding: '3px 0 0 5px',
  },
});

const FirstPage = ({ data }) => {
  const ramValues = data && data.services.find((item) => item.name === 'Ping RAM');
  const switchValues = data && data.services.find((item) => item.name === 'Ping Switch');

  const searchStr = 'Ping Plenty'.toLowerCase();
  const pingPlenty =
    data && data.services.find((item) => item.name.toLowerCase().startsWith(searchStr) && item.num_services > 0);

  const getSum = () => {
    const sum =
      ramValues?.num_services > 0 ? parseFloat(ramValues?.price_per_month) : parseFloat(switchValues?.price_per_month);

    if (pingPlenty?.price_per_month > 0) {
      return sum + parseFloat(pingPlenty?.price_per_month);
    }

    return sum;
  };

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>
        <Text style={styles.title}>HYRESAVTAL</Text>
        <View style={{ ...styles.input, marginBottom: 10, height: 27 }}>
          <Text style={styles.label}>Avtalsnummer</Text>
          <Text style={styles.text}>{data?.inquiry_number}</Text>
        </View>

        <View style={styles.tableHeader}>
          <Text style={styles.sectionName}>HYRESTAGARE</Text>
          <Text style={styles.sectionName}>LEVERANTÖR</Text>
        </View>

        <View style={styles.table}>
          <View style={{ ...styles.input, width: '50%' }}>
            <Text style={styles.label}>Org. nr</Text>
            <Text style={styles.text}>{data?.company.organization_number}</Text>
          </View>
          <View
            style={{
              ...styles.input,
              borderLeft: 'none',
              width: '50%',
            }}
          >
            <Text style={styles.label}>Org. nr</Text>
            <Text style={styles.text}>556907-3256</Text>
          </View>
          <View
            style={{
              ...styles.input,
              width: '50%',
              borderTop: 'none',
            }}
          >
            <Text style={styles.label}>Namn</Text>
            <Text style={styles.text}>{data?.company.name}</Text>
          </View>

          <View
            style={{
              ...styles.input,
              borderLeft: 'none',
              width: '50%',
              borderTop: 'none',
            }}
          >
            <Text style={styles.label}>Namn</Text>
            <Text style={styles.text}>Frontdesk Nordic AB</Text>
          </View>

          <View
            style={{
              ...styles.input,
              width: '30%',
              borderTop: 'none',
            }}
          >
            <Text style={styles.label}>Avtalskontakt</Text>
            <Text style={styles.text}>{data?.company?.signers?.[0]?.name ?? ''}</Text>
          </View>
          <View
            style={{
              ...styles.input,
              width: '20%',
              borderTop: 'none',
              borderLeft: 'none',
            }}
          >
            <Text style={styles.label}>Tel</Text>
            <Text style={styles.text}>{data?.company?.signers?.[0]?.phone_number ?? ''}</Text>
          </View>
          <View
            style={{
              ...styles.input,
              borderLeft: 'none',
              width: '30%',
              borderTop: 'none',
            }}
          >
            <Text style={styles.label}>Avtalskontakt</Text>
            <Text style={styles.text}>{data?.manager?.username ?? ''}</Text>
          </View>
          <View
            style={{
              ...styles.input,
              width: '20%',
              borderTop: 'none',
              borderLeft: 'none',
            }}
          >
            <Text style={styles.label}>Tel</Text>
            <Text style={styles.text}>{data?.manager?.phone_number ?? ''}</Text>
          </View>

          <View
            style={{
              ...styles.input,
              width: '100%',
              borderTop: 'none',
            }}
          >
            <Text style={styles.label}>Ev avvikande fakturaadress</Text>
            <Text> </Text>
          </View>

          <View
            style={{
              ...styles.input,
              width: '100%',
              borderTop: 'none',
            }}
          >
            <Text style={styles.label}>Leveransadress / Installationsplats</Text>
            <Text style={styles.text}>
              {data?.company.postal_address}, {data?.company.zip_code}, {data?.company.state}
            </Text>
          </View>

          <View style={{ ...styles.tableHeader, marginTop: 10 }}>
            <Text style={styles.sectionName}>HYRESFÖREMÅL</Text>
          </View>
          <View style={{ ...styles.table, height: 40 }}>
            <View style={{ ...styles.input, width: '80%' }}>
              <Text style={styles.label}>Typ</Text>
              <Text
                style={{
                  fontSize: 12,
                  fontFamily: 'Helvetica',
                  paddingLeft: 5,
                }}
              >
                Telefoni Frontdesk - Ping{' '}
                {ramValues?.num_services > 0
                  ? `RAM ${parseFloat(ramValues?.price_per_month)}kr/mån`
                  : `Switch ${parseFloat(switchValues?.price_per_month)}kr/mån`}{' '}
                {pingPlenty?.price_per_month > 0
                  ? `+ Ping Plenty ${parseFloat(pingPlenty?.price_per_month)}kr/mån`
                  : ''}
                {'\n'}
                Ramavtalsnummer - {data?.agreement_id}
              </Text>
            </View>
            <View
              style={{
                ...styles.input,
                borderLeft: 'none',
                width: '20%',
              }}
            >
              <Text style={styles.label}>Serienr</Text>
              <Text> </Text>
            </View>
          </View>

          <View style={{ ...styles.tableHeader, marginTop: 10 }}>
            <Text style={styles.sectionName}>
              HYRA, HYRESVILLKOR <Text style={{ fontSize: 8 }}>(belopp exkl. moms där annat ej anges)</Text>
            </Text>
          </View>
          <View style={styles.table}>
            <View style={{ ...styles.input, width: '20%' }}>
              <Text style={styles.label}>Hyresperiod (antal månader)</Text>
              <Text style={styles.inputValues}>
                {ramValues?.num_services > 0 ? data?.ping_ram_duration : data?.ping_switch_duration}
              </Text>
            </View>
            <View
              style={{
                ...styles.input,
                borderLeft: 'none',
                width: '20%',
              }}
            >
              <Text style={styles.label}>Hyra per månad Service per manad</Text>
              <Text style={styles.inputValues}>
                {getSum()}
                kr
              </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '20%',
                borderLeft: 'none',
              }}
            >
              <Text style={styles.label}>Betalningsperiod</Text>
              <Text style={styles.inputValues}>Kvartalsvis</Text>
            </View>
            <View
              style={{
                ...styles.input,
                borderLeft: 'none',
                width: '20%',
              }}
            >
              <Text style={styles.label}>Uppläggningsavgift</Text>
              <Text style={styles.inputValues}>0 kr</Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '20%',
                borderLeft: 'none',
              }}
            >
              <Text style={styles.label}>Aviavgift</Text>
              <Text style={styles.inputValues}>39 kr</Text>
            </View>

            <View
              style={{
                ...styles.input,
                width: '40%',
                borderTop: 'none',
              }}
            >
              <Text style={styles.label}>På varje förfallodag ska belastas bankgiro nr. (om autogiro används) </Text>
              <View style={styles.squares}>
                <Text style={styles.square}> </Text>
                <Text style={styles.square}> </Text>
                <Text style={styles.square}> </Text>
                <Text style={styles.square}> </Text>
                <Text>—</Text>
                <Text style={styles.square}> </Text>
                <Text style={styles.square}> </Text>
                <Text style={styles.square}> </Text>
                <Text style={styles.square}> </Text>
              </View>
            </View>
            <View
              style={{
                ...styles.input,
                width: '60%',
                borderLeft: 'none',
                borderTop: 'none',
              }}
            >
              <Text style={{ fontSize: 8, padding: 3 }}>
                Hyra ska betalas kvartalsvis i förskott senast den första dagen i varje kvartal. En första hyresavgift
                (initialavgift) skall erläggas för tiden från leverans av föremålet till avtalets startdag.
                Initialavgiften är 1/30 av månadsavgiften from leveransdag till avtalets startdag. Avi,
                uppläggningsavgift samt initialavgift förfaller tillbetalning efter leverans.
              </Text>
            </View>
          </View>

          <View style={{ ...styles.tableHeader, marginTop: 10 }}>
            <Text style={styles.sectionName}>SÄRSKILDA VILLKOR</Text>
          </View>
          <View style={styles.table}>
            <View style={{ ...styles.input, width: '100%' }}>
              <Text style={{ fontSize: 7, padding: 3 }}>
                För leverans av Utrustningen gäller villkoren i separat avtal mellan HT med Frontdesk Nordic AB
                (”Leverantören”), bilaga [ Villkor] och för leverans och installation gällande Utrustningen gäller de
                delar av Leverantörens allmänna villkor som avser leverans och installation, bilaga [Villkor], punkterna
                [6,7] I övrigt gäller som ovan anges i detta avtal angivna villkor samt ”GRENKELEASING AB Allmänna
                villkor 19SE01”, vilka HT tagit del av och godkänt.
              </Text>
            </View>
          </View>
          <View style={{ ...styles.input, width: '100%', marginTop: 5 }}>
            <Text style={{ fontSize: 7, padding: 3 }}>
              Vi (Hyrestagare, ”HT”) har informerats om överlåtelsen av rättigheterna enligt detta avtal till
              GRENKELEASING AB (”Grenke”), org. nr 556649- 3929. Samtliga rättigheter enligt detta avtal, inklusive
              äganderätten till hyresföremålet, överlåts således på Grenke i samband med undertecknandet. Utöver
              ovanstående villkor gäller ”GRENKELEASING AB Allmänna villkor Contract Assignment Office Direct”, vilka HT
              tagit del av och godkänt. Muntliga eller skriftliga avtal eller utfästelser av leverantören eller ombud
              för Grenke gäller ej, såvida dessa inte skriftligen godkänts av Grenke. Grenke ansvarar inte för garanti,
              service, underhåll, förbrukning och tjänster (”Service & Underhåll”) som kan ingå i hyran eller
              hyresföremålets skick eller lämplighet för HT. HT äger inte innehålla någon betalning till Leverantören
              pga. fel eller brist i hyresföremålet eller med anledning av fel eller brist i Leverantörens fullgörande
              av sina åtaganden. HT har informerats att leverantören fakturerar samtliga avgifter enligt detta avtal;
              betalning av såväl avgifter hänförliga till Service & Underhåll som uthyrningen av hyresföremålet ska
              således ske till av leverantören anvisat bankkonto. HT har också informerats om att Grenke när som helst
              under avtalstiden kan meddela att betalning av hyresavgifter och andra avgifter hänförliga till
              uthyrningen av hyresföremålet istället ska ske till av Grenke anvisat konto. Detta avtal har upprättats i
              två likalydande exemplar, varav parterna tagit var sitt. Avtalet är inte bindande för Grenke förrän det
              undertecknats av denne. Avtalet är bindande för HT genom dennes underskrift.
            </Text>
          </View>

          <View style={{ ...styles.tableHeader, marginTop: 10 }}>
            <Text style={styles.sectionName}>UNDERSKRIFT HYRESTAGARE</Text>
            <Text style={styles.sectionName}>UNDERSKRIFT UTHYRARE</Text>
          </View>
          <View style={styles.table}>
            <View style={{ ...styles.input, width: '25%' }}>
              <Text style={styles.label}>Ort</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                borderLeft: 'none',
                width: '25%',
              }}
            >
              <Text style={styles.label}>Datum</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '25%',
                borderLeft: 'none',
              }}
            >
              <Text style={styles.label}>Ort</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '25%',
                borderLeft: 'none',
              }}
            >
              <Text style={styles.label}>Datum</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '50%',
                borderTop: 'none',
                height: 40,
              }}
            >
              <Text style={styles.label}>Underskrift</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '50%',
                borderLeft: 'none',
                borderTop: 'none',
                height: 40,
              }}
            >
              <Text style={styles.label}>Underskrift</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '50%',
                borderTop: 'none',
              }}
            >
              <Text style={styles.label}>Namnförtydligande</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '50%',
                borderLeft: 'none',
                borderTop: 'none',
              }}
            >
              <Text style={styles.label}>Namnförtydligande</Text>
              <Text> </Text>
            </View>
          </View>

          <View style={{ ...styles.tableHeader, marginTop: 10 }}>
            <Text style={styles.sectionName}>BORGEN</Text>
            <Text style={styles.sectionName}>LEVERANSGODKÄNNANDE</Text>
          </View>

          <View style={styles.table}>
            <View
              style={{
                ...styles.input,
                width: '50%',
                height: 45,
                padding: 3,
              }}
            >
              <Text style={styles.label}>
                Undertecknad går härmed i borgen för HT:s förpliktelser enligt detta avtal såsom för egen skuld. Detta
                borgensåtagande innebär att UH eller annan som övertagit UH:s rättigheter kan vända sig till
                borgensmannen för betalning enligt detta avtal.
              </Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                borderLeft: 'none',
                width: '50%',
                height: 45,
                padding: 3,
              }}
            >
              <Text style={styles.label}>
                Viktigt: På grundval av leveransgodkännandet betalar GRENKE LEASING AB (UH) köpeskillingen till och
                förvärvar därigenom hyresföremålet från leverantören. Underlåter därför HT att göra en funktionskontroll
                och/eller undertecknar HT leveransgodkännandet trots att hyresföremålet helt eller delvis ej levererats
                eller trots att hyresföremålet levererats i ett felaktigt eller bristfälligt skick ska HT ersätta UH för
                därigenom uppkommen skada.
              </Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '25%',
                borderTop: 'none',
              }}
            >
              <Text style={styles.label}>Ort</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '25%',
                borderLeft: 'none',
                borderTop: 'none',
              }}
            >
              <Text style={styles.label}>Datum</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '25%',
                borderTop: 'none',
                borderLeft: 'none',
              }}
            >
              <Text style={styles.label}>Ort</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '25%',
                borderLeft: 'none',
                borderTop: 'none',
              }}
            >
              <Text style={styles.label}>Datum</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '50%',
                borderTop: 'none',
                height: 40,
              }}
            >
              <Text style={styles.label}>Underskrift borgensman</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '50%',
                borderLeft: 'none',
                borderTop: 'none',
                height: 40,
              }}
            >
              <Text style={styles.label}>Underskrift</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '50%',
                borderTop: 'none',
              }}
            >
              <Text style={styles.label}>Namnförtydligande borgensman</Text>
              <Text> </Text>
            </View>
            <View
              style={{
                ...styles.input,
                width: '50%',
                borderLeft: 'none',
                borderTop: 'none',
              }}
            >
              <Text style={styles.label}>Namnförtydligande</Text>
              <Text> </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

FirstPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
};

FirstPage.defaultProps = {
  data: null,
};

export default FirstPage;
