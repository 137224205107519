import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { ErrorMessage, StyledButton, Input } from '../../../components/simple';
import styles from './styles.module.scss';
import { patch } from '../../../services/API';
import { loginPage } from '../../../router/routes';

const ForgotPasswordForm = () => {
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      const data = await patch('/auth/forgot-password', values);
      if (data.status === 200) {
        setMessage('Ett nytt lösenord har skickats till din e-post');
        setTimeout(() => navigate(loginPage), 2000);
      }
    },
  });

  return (
    <div className={styles.forgotPasswordForm}>
      <h3 className={styles.formHeader}>Har du glömt ditt lösenord?</h3>
      <p className={styles.formInfo}>Fyll i din e-postadress så skickas nytt lösenord.</p>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <Input
          name="email"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.email}
          label="E-postadress"
        />
        {error && <ErrorMessage text="User with this credentials not found" />}
        {message && <p className={styles.success}>{message}</p>}
        <StyledButton type="submit" color="yellow" text="Skicka" className={styles.button} />
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
