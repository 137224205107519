import {
  downloadAgreements,
  startedAgreements,
  newAgreementStep1,
  allDownloadAgreements,
  allStartedAgreements,
} from '../router/routes';

const itemCreator = (name, path) => ({
  name,
  path,
});

export const sidebarItems = [
  itemCreator('Mina avtal', downloadAgreements),
  itemCreator('Pågående avtal', startedAgreements),
  itemCreator('Skapa nytt avtal', newAgreementStep1),
];

export const adminItems = [
  itemCreator('Ping avtal', allDownloadAgreements),
  itemCreator('Ping pågående avtal', allStartedAgreements),
  itemCreator('Skapa nytt avtal', newAgreementStep1),
];

export const inputName = (path) => (path === downloadAgreements ? 'Sök avtal...' : 'Pågående avtal...');
