import React from 'react';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { roamingPdfStyles } from '../../../../helpers/pdfCommonStyles';

const styles = StyleSheet.create({
  ...roamingPdfStyles,
  title: {
    fontSize: 31,
    fontFamily: 'Como-ExtraBold',
    marginBottom: 20,
  },
  wrapper: {
    width: 400,
    paddingLeft: 40,
  },
  tableContainer: {
    ...roamingPdfStyles.tableContainer,
  },

  tableHeader: {
    ...roamingPdfStyles.tableHeader,
    fontSize: 11,
    paddingBottom: 15,
  },

  container: {
    ...roamingPdfStyles.container,
    width: '100%',
  },

  cell: {
    ...roamingPdfStyles.cell,
    textAlign: 'left',
  },
});

const mobileTableCreator = (country, feed) => ({
  country,
  feed,
});

const mobileTableValues = [
  mobileTableCreator('USA', '5 GB/ 199 kr'),
  mobileTableCreator('Storbritannien/Gibraltar', '5 GB/ 199 kr'),
  mobileTableCreator('Schweiz', '5 GB/ 199 kr'),
  mobileTableCreator('Turkiet', '5 GB/ 199 kr'),
  mobileTableCreator('Thailand', '5 GB/ 199 kr'),
  mobileTableCreator('Kanada', '3 GB/ 199 kr'),
  mobileTableCreator('Kina', '3 GB/ 199 kr'),
  mobileTableCreator('Hong Kong', '3 GB/ 199 kr'),
  mobileTableCreator('Singapore', '3 GB/ 199 kr'),
  mobileTableCreator('Israel', '3 GB/ 199 kr'),
  mobileTableCreator('Sydkorea', '3 GB/ 199 kr'),
  mobileTableCreator('Malaysia', '3 GB/ 199 kr'),
  mobileTableCreator('Taiwan', '3 GB/ 199 kr'),
  mobileTableCreator('Indonesien', '3 GB/ 199 kr'),
];

const ThirdPage = () => (
  <Page style={styles.page}>
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.logo}>
          <Image src={logo} />
        </View>
      </View>

      <View style={styles.containerWrapper}>
        <View style={styles.tableContainer}>
          <View style={styles.wrapper}>
            <Text style={{ ...styles.tableTitle, paddingLeft: 20 }}>Mobildata - Top-up data</Text>
            <View>
              <View style={styles.tableHeader}>
                <Text style={{ ...styles.cell, width: 200, paddingLeft: 20 }}>Land</Text>
                <Text style={{ ...styles.cell, width: 200, textAlign: 'center' }}>Påfyllning</Text>
              </View>
              <>
                {mobileTableValues.map((item) => (
                  <View style={styles.row} key={item.roamingzon}>
                    <Text style={{ ...styles.cell, width: 200, paddingLeft: 20 }}>{item.country}</Text>
                    <Text style={{ ...styles.cell, width: 200, textAlign: 'center' }}>{item.feed}</Text>
                  </View>
                ))}
              </>
            </View>
            <Text style={{ ...styles.tableInfo, paddingLeft: 20 }}>
              Pris SEK exkl. moms.{'\n'}
              Gäller i 14 dagar efter aktivering. {'\n'}
              Aktiveras med sms vid ankomst till land enligt.{'\n'}
            </Text>
          </View>
        </View>
      </View>
    </View>
    <View style={styles.footer}>
      <Text>www.pingaway.se</Text>
    </View>
  </Page>
);

export default ThirdPage;
