/* eslint-disable no-unused-vars */
import React from 'react';
import { Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { roamingPdfStyles } from '../../../../helpers/pdfCommonStyles';

const styles = StyleSheet.create({
  ...roamingPdfStyles,
  container: {
    ...roamingPdfStyles.container,
    height: 800,
  },
});

const FourPage = () => (
  <Page style={styles.page}>
    <View style={styles.container}>
      <View style={styles.logo}>
        <Image src={logo} />
      </View>

      <View style={styles.titleContainer}>
        <Text style={styles.title}>Roamingzoner -{'\n'}Samtalstrafik/ SMS</Text>
      </View>

      <View style={styles.content}>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 1 EU/EES</Text>
          <Text style={styles.sectionText}>
            Belgien, Bulgarien, Cypern, Frankrike, Franska Guyana, Franska Västindien, Gibraltar, Grekland, Guadeloupe,
            Irland, Island. Italien, Kroatien, Liechtenstein, Luxemburg, Malta, Martinique, Nederländerna, Polen,
            Portugal, Réunion, Rumänien, Slovakien, Slovenien, Spanien, Tjeckien, Tyskland, Ungern, Österrike, Danmark,
            Estland, Finland, Lettland, Litauen, Norge
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 2A</Text>
          <Text style={styles.sectionText}>Färöarna, Schweiz, Storbritannien</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 2B</Text>
          <Text style={styles.sectionText}>Guernsey, Isle of Man, Jersey</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 2C</Text>
          <Text style={styles.sectionText}>Andorra, Monaco, Turkiet</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 3</Text>
          <Text style={styles.sectionText}>
            Albanien, Armenien, Azerbajdzjan, Bosnien-Hercegovina, Georgien, Kazakstan, Kirgizistan, Kosovo, Makedonien,
            Moldavien, Montenegro, Serbien, Tadjikistan, Ukraina, Uzbekistan, Vitryssland
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>ROAMINGZON 4</Text>
          <Text style={styles.sectionText}>
            Am. Jungfruöarna, Argentina, Australien, Bermuda, Brasilien, Chile, Förenade Arabemiraten, Hong Kong,
            Indien, Indonesien, Israel, Japan, Kanada, Kina, Malaysia, Mexiko, Nya Zeeland, Ryssland, Puerto Rico,
            Singapore, Sydafrika, Sydkorea, Taiwan (Kina), USA
          </Text>
        </View>

        <View style={{ ...styles.section }}>
          <Text style={styles.sectionTitle}>ROAMINGZON 5A</Text>
          <Text style={styles.sectionText}>
            Afghanistan, Algeriet, Angola, Anguilla, Bahrain, Bangladesh, Barbados, Bhutan, Bolivia, Botswana, Brit.
            Jungfruöarna, Burkina Faso, Burundi, Caymanöarna, Colombia, Costa Rica, Kongo-Kinshasa, Dominica,
            Dominikanska Republiken, Ecuador, Egypten, El Salvador, Elfenbenskusten,
          </Text>
        </View>

        <View style={{ ...styles.section, marginLeft: 40 }}>
          <Text style={styles.sectionText}>
            Fiji, Filippinerna, Franska Polynesien, Gambia, Ghana, Grenada, Grönland, Guatemala, Guinea, Guyana,
            Honduras, Irak, Iran, Jamaica, Kambodja,Kenya, Laos, Lesotho, Libanon, Liberia, Libyen, Macau, Maldiverna,
            Marocko, Mauritius, Mocambique, Mongoliet, Myanmar, Namibia, Nederländska Antillerna, Nepal, Nicaragua,
            Nigeria, Oman, Pakistan, Palestina, Panama, Paraguay, Peru, Qatar, KongoBrazzaville, Rwanda, Saint Lucia,
            Saint Vincent & Grenadinerna, Saudiarabien, Seychellerna, Sierra Leone, Sri Lanka, Sudan, Surinam, Sydsudan,
            Togo, Trinidad & Tobago, Tunisien, Turkmenistan, Turks & Caicosöarna, Uganda, Uruguay, Vietnam, Yemen
          </Text>
        </View>

        <View style={{ ...styles.section, marginLeft: 40 }}>
          <Text style={styles.sectionTitle}>ROAMINGZON 5B</Text>
          <Text style={styles.sectionText}>
            Antigua & Barbuda, Aruba, Belize, Brunei, Djibouti, Jordanien, Kamerun, Kuwait, Madagaskar, Mali,
            Mauretanien, Montserrat, Saint Kitts & Nevis, Senegal, Syrien, Venezuela
          </Text>
        </View>

        <View style={{ ...styles.section, marginLeft: 40 }}>
          <Text style={styles.sectionTitle}>ROAMINGZON 5C</Text>
          <Text style={styles.sectionText}>
            Amerikanska Samoa, Benin, Bahamas, Curacao, Ekvatorialguinea, Etiopen, Falklandsöarna, Gabon, Kap Verde,
            Kuba, Malawi, Niger, Nya Kaledonien, Somalia, Swaziland, Tanzania, Tchad, Vanuatu, Zambia, Zimbabwe, Samt
            övriga ej specificerade länder/roamingpartners
          </Text>
        </View>

        <View style={{ ...styles.section, marginLeft: 40 }}>
          <Text style={styles.sectionTitle}>ROAMINGZON 6</Text>
          <Text style={styles.sectionText}>Maritime, GSM on ships</Text>
        </View>

        <View style={{ ...styles.section, marginLeft: 40 }}>
          <Text style={styles.sectionTitle}>ROAMINGZON 7</Text>
          <Text style={styles.sectionText}>Satellit-operatörer</Text>
        </View>

        <View style={{ ...styles.section, marginLeft: 40 }}>
          <Text style={styles.sectionTitle}>ROAMINGZON THAILAND</Text>
          <Text style={styles.sectionText}>Thailand</Text>
        </View>
      </View>
    </View>
    <View style={styles.footer}>
      <Text>www.pingaway.se</Text>
    </View>
  </Page>
);

export default FourPage;
